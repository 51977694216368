/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

import React, { useMemo, useContext, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';

import * as ASConfig from './ASConfig';
import { AppContext } from '../../Utils';

const gvCloudUrl = process.env.REACT_APP_URL_BASE;
const loginUrl = `${process.env.REACT_APP_URL_ASCLOUD_API}login`;
const webSocketUrl = process.env.REACT_APP_URL_WEBSOCKET;

const enumServicePath = {
    [ASConfig.EnumServiceType.Access]:   'access',
    [ASConfig.EnumServiceType.Map]:      'map'
};

export default class ASUtils extends React.Component {

    _wsKeepAliveTime = 9.5 * 60 * 1000; // Websocket will be disconnected when no transmission for more than 10 minutes.
    _wsReconnectTime = 115 * 60 * 1000; // Websocket will be disconnected by AWS for every 2 hours.
    _wsMaxErrorCount = 20;
    _wsDelayTolerateTime = 1 * 60 * 1000; // Edge will going to sleep mode when the page inactived, Cause js not work
    _ws = null;
    _wsKeepAliveTimer = null;
    _wsErrorCount = 0;
    _wsFirstConnect = true;
    _wsReconnectTimer = null;
    _wsPositiveDisconnect = false;
    _wsReloadPage = false;

    constructor(props) {
        super(props);

        this.state = {
            wsClientId: null,

            debugMsg: this.debugMsg,
            ajaxLogin: this.ajaxLogin,
            sendWS: this.sendWS,
            getWsClientId: this.getWsClientId
        };
    }

    componentDidMount() {
        var strDate = new Date().getTime().toString(16);
        strDate = strDate.substring(strDate.length - 6);
        this.setState({wsClientId: `${this.props.serviceType}_${Math.round(Math.random() * 1000).toString(16)}${strDate}`});
    }

    componentWillUnmount() {
        clearTimeout(this._wsKeepAliveTimer);
        clearTimeout(this._wsReconnectTimer);
        if (this._ws) {
            this._ws.onopen = null;
            this._ws.onmessage = null;
            this._ws.onclose = null;
            this._ws.onerror = null;
            this._ws.close();
        }
    }

    debugMsg = (module, msg) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            const padZero = (val, length) => {
                return val.toString().padStart(length || 2, '0');
            };
            var now = new Date(),
                time = `${now.getFullYear()}-${padZero(now.getMonth()+1)}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}.${padZero(now.getMilliseconds(), 3)}`;
            console.log(`${time} [${module}]: ${msg}`);
        }
    };

    ajaxLogin = (callback) => {
        callback = callback || function(){};

        fetch(loginUrl, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.props.token
            }),
            body: JSON.stringify({
                // service_type: this.props.serviceType,
                device_type: 101,   // (1: AS, 2: LPR, 101: Browser, 102: Mobile)
                timezone: JSON.stringify({tz_id: Intl.DateTimeFormat().resolvedOptions().timeZone})
            })
        })
        .then(res => res.json())
        .then(data => {
            if (!data.errcode) {
                callback(true, data.data[0]);
                if (!this._ws) {
                    this._connectWS();
                }
            } else if (data.errcode === ASConfig.EnumErrorCode.ERR_LOGIN_FAILED) {
                window.location = `${gvCloudUrl}?redirect=/${enumServicePath[this.props.serviceType]}&pathname=${window.location.pathname}`;
            } else {
                callback(false, ASConfig.EnumErrorMessage[data.errcode] ? data.errcode : data.errmsg);
            }
        })
        .catch(e => {
            callback(false, e.toString());
            console.log(e);
        });
    };

    /* -------- WebSocket Start -------- */
    _debugMsgWS = (msg, send) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            var strSend = send ? 'Send' : (send === false ? 'Receive' : '');
            var strCmd = '';

            var getStatus = (enumStatus, status) => {
                var arrStatus = [];
                Object.keys(enumStatus).forEach(key => {
                    if (key !== 'NONE' && (status & enumStatus[key]) === enumStatus[key]) {
                        arrStatus.push(key);
                    }
                });
                if (arrStatus.length > 0) {
                    return ` (${arrStatus.join(' / ')})`;
                }
                return '';
            };
            try {
                var data = JSON.parse(msg);
                if (data.cmd_id) {
                    if (data.cmd_id === -1) {
                        strCmd = 'KeepAlive';
                    } else {
                        strCmd = Object.keys(ASConfig.EnumWebSocketCmd).find(key => ASConfig.EnumWebSocketCmd[key] === data.cmd_id);
                    }

                    if (data.cmd_id === ASConfig.EnumWebSocketCmd.DEVICE_STATUS_CHANGED) {
                        strCmd += getStatus(ASConfig.EnumDeviceStatus, parseInt(data.data2));
                    } else if (data.cmd_id === ASConfig.EnumWebSocketCmd.GATE_STATUS_CHANGED) {
                        strCmd += getStatus(ASConfig.EnumGateStatus, parseInt(data.data2));
                    }
                }
            } catch {}

            this.debugMsg('ASCloudSocket', `${strSend}: ${strCmd || ''}, ${msg}`);
        }
    };

    _connectWS = (reconnect) => {
        var _self = this,
            _ws;

        const clearWebsocket = (ws) => {
            ws.onopen = null;
            ws.onmessage = null;
            ws.onclose = null;
            ws.onerror = null;
            ws.close();
            ws = null;
        };

        if (!reconnect && _self._ws) {
            clearWebsocket(_self._ws);
        }

        _self._wsPositiveDisconnect = true;
        _ws = new WebSocket(`${webSocketUrl}?p1=${this.props.token}&p2=${this.state.wsClientId}`);
        
        _ws.onopen = function(e) {
            _self._debugMsgWS('Open');
            _self._wsErrorCount = 0;

            if (reconnect && _self._ws) {
                clearWebsocket(_self._ws);
            }
            _self._ws = _ws;
            _self._wsPositiveDisconnect = false;
            
            if (!_self._wsFirstConnect) {   // if reconnect websocet, then get all device
                var notifyData = {cmd_id: ASConfig.EnumWebSocketCmd.RECONNECT_WEBSOCKET};
                _self.props.onNotify(notifyData);
            }
            _self._wsFirstConnect = false;
        };

        _ws.onmessage = function(e) {
            _self._debugMsgWS(e.data, false);
            _self._keepWSAlive();
            var data = {};
            try {
                data = JSON.parse(e.data);
            } catch {}

            if (_self._wsReloadPage) {
                return;
            }
            
            if (data.un_time && (new Date().getTime() - data.un_time * 1000 > _self._wsDelayTolerateTime)) {
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self.props.onNotify(data);
        };

        _ws.onclose = function(e) {
            if (!_self._wsPositiveDisconnect) { // if not possive to discconet, then reload page
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self._ws = null;
            _self._debugMsgWS(`Close, wasClean: ${e.wasClean}, code: ${e.code}, reason: ${e.reason}, error count: ${_self._wsErrorCount}`);

            if (_self._wsErrorCount < _self._wsMaxErrorCount) {
                setTimeout(() => {
                    _self._connectWS();
                }, _self._wsErrorCount * 1000);
            } else {
                window.location = `${gvCloudUrl}?redirect=/${enumServicePath[_self.props.serviceType]}&pathname=${window.location.pathname}`;
            }
        };

        _ws.onerror = function(e) {
            _self._ws = null;
            _self._debugMsgWS(`Error, error count: ${_self._wsErrorCount}, message: ${e.message}`);
            _self._wsErrorCount++;
        };

        _self._keepWSAlive();
        
        clearTimeout(_self._wsReconnectTimer);
        _self._wsReconnectTimer = setTimeout(() => {
            _self._connectWS(true);
        }, _self._wsReconnectTime);
    };

    _keepWSAlive = () => {
        clearTimeout(this._wsKeepAliveTimer);
        this._wsKeepAliveTimer = setTimeout(() => {
            this.sendWS({
                cmd_id: -1
            }, true);
        }, this._wsKeepAliveTime);
    };

    sendWS = (params) => {
        if (this._ws) {
            params = {...params, action: "message"};
            var str = JSON.stringify(params);
            this._ws.send(str);
            this._debugMsgWS(str, true);
            this._keepWSAlive();
        }
    };
    /* -------- WebSocket End -------- */

    getWsClientId = () => {
        return this.state.wsClientId;
    };

    render() {
        return null;
    }
}
ASUtils.defaultProps = {
    serviceType: ASConfig.EnumServiceType.Access,
    token: '',
    onNotify: function(data) {}
};

export const FormatedTime = function({timeFormat, localtime, utctime, dst, fixedTime, ...props}) {
    const { accountInfo } = useContext(AppContext);
    
    const time_format = useMemo(() => {
        return timeFormat || accountInfo.time_format || 'MM-DD-YYYY HH:mm:ss';
    }, [timeFormat, accountInfo.time_format]);

    const countdownTime = useMemo(() => {
        if (!props.children || fixedTime || !utctime) return '';

        var log_utc_time = new Date(utctime),
            t = new Date(),
            currentUTC = t.getTime() + (t.getTimezoneOffset() * 60000);
        var diff = currentUTC - log_utc_time.getTime();
        
        if (diff < 45 * 1000) {
            return <FormattedMessage id='seconds_ago' defaultMessage='a few seconds ago' />;
        } else if (diff < 59 * 60 * 1000) {
            var mins = Math.round(diff / (60 * 1000));
            if (mins === 1) {
                return <FormattedMessage id='minute_ago' defaultMessage='1 minute ago' />;
            } else {
                return <FormattedMessage id="minutes_ago_format" defaultMessage="{0} minutes ago" values={{0: `${mins}`}} />;
            }
        }
        
        return '';
    }, [props.children, fixedTime, utctime]);

    const time = useMemo(() => {
        var strTime = moment(new Date(localtime)).format(time_format);
        if (dst === ASConfig.EnumLogDSTType.InDSTOverlay) {
            strTime += ' (DST)';
        }
        return strTime;
    }, [localtime, time_format, dst]);

    if (props.children) {
        return props.children(time, countdownTime || time);
    } else {
        return time;
    }
};
FormatedTime.defaultProps = {
    timeFormat: '',
    localtime: '',
    utctime: '',
    dst: ASConfig.EnumLogDSTType.None,
    fixedTime: false,
};

export function GvJsPlayer() {
    useEffect(() => {
        if (!document.head.querySelector('link[href="css/gvplayer.css"]')) {
            var linkCss = document.createElement('link');
            linkCss.type = 'text/css';
            linkCss.rel = 'stylesheet';
            linkCss.href = 'css/gvplayer.css';
            document.head.append(linkCss);
        }

        if (!document.head.querySelector('script[src="js/manifest.min.js"]')) {
            var scriptManifest = document.createElement('script');
            scriptManifest.type = 'text/javascript';
            scriptManifest.src = 'js/manifest.min.js';
            document.head.append(scriptManifest);
        }

        if (!document.head.querySelector('script[src="js/gvplayer.min.js"]')) {
            var scriptGvplayer = document.createElement('script');
            scriptGvplayer.type = 'text/javascript';
            scriptGvplayer.src = 'js/gvplayer.min.js';
            document.head.append(scriptGvplayer);
        }
    }, []);
    return null;
}

const liveMaxIdleTime = 5 * 60 * 1000;  // Live Video Idle Time (millisecond)
export const LiveVideoIdle = ({playerStatus, onIdle, onActive, ...props}) => {
    const [playerRealStatus, setPlayerRealStatus] = useState(false);

    useEffect(() => {
        setPlayerRealStatus(playerStatus);
    }, [playerStatus]);

    const handleIdle = () => {
        if (playerRealStatus) {
            onIdle();
            console.log('Player Idle.');
            setTimeout(() => {
                setPlayerRealStatus(true);
            }, 300);
        }
    };

    const handleAcive = () => {
        if (playerRealStatus) {
            onActive();
            console.log('Player Active.');
        }
    };

    useIdleTimer({
        onIdle: handleIdle,
        onActive: handleAcive,
        timeout: liveMaxIdleTime
    });

    return null;
};
LiveVideoIdle.defaultProps = {
    playerStatus: false,
    onIdle: function() {},
    onActive: function() {}
};

export const timeScaleToStr = (num) => {    // num: 0 ~ 288
    var mins = num * 5;
    return Math.floor(mins/60).toString().padStart(2, '0') + ':' + (mins%60).toString().padStart(2, '0');
};

export const strToTimeScale = (str) => {    // str: 23:45
    if (str.includes(':')) {
        var [hour, min] = str.split(':');
        return (parseInt(hour) * 60 + parseInt(min)) / 5;
    }
    return 0;
};

export const timeScaleToHex = (strScale) => {
    var hexSize = 32, hexLength = 8;
    var strHex = '', i;

    for (i = 0; i < ASConfig.timezoneScaleCount; i = i + hexSize) {
        strHex += parseInt(strScale.substr(i, hexSize), 2).toString(16).padStart(hexLength, '0');
    }

    return strHex;
};

export const hexToTimeScale = (strHex) => {
    var scale = '';
    var hexSize = 32, hexLength = 8;

    var hexTotalLength = ASConfig.timezoneScaleCount / (hexSize / hexLength) , i;

    if (strHex.length === hexTotalLength) {
        for (i = 0; i < hexTotalLength; i = i + hexLength) {
            scale += parseInt(strHex.substr(i, hexLength), 16).toString(2).padStart(hexSize, '0');
        }
    } else {
        scale = getEmptyTimeScale();
    }

    return scale;
};

export const getEmptyTimeScale = (type) => {
    return Array(ASConfig.timezoneScaleCount).fill(type || 0).join('');
};

export const useASConfig = () => {
    const intl = useIntl();
    const { regionList, accessRuleList, alertList, specialDayList, accountList, photoViewConfig, accountInfo,
            // ajaxASCloud, serviceType
    } = useContext(AppContext);

    const getRegion = (rg_id) => {
        return regionList.find(item => item.rg_id === rg_id);
    };

    const getAccessRule = (ar_id) => {
        return accessRuleList.find(item => item.ar_id === ar_id);
    };

    const getAlert = (nt_id) => {
        return alertList.find(item => item.nt_id === nt_id);
    };

    const getSpecialDay = (sd_id) => {
        return specialDayList.find(item => item.sd_id === sd_id)
    };

    const getAccount = (id) => {
        return accountList.find(item => item.id === id);
    };

    const getRegionName = (rg_id) => {
        return getRegion(rg_id)?.rg_name;
    };

    const getAccessRuleName = (ar_id) => {
        return getAccessRule(ar_id)?.ar_name;
    };

    const getSpecialDayName = (sd_id) => {
        if (sd_id < ASConfig.specialDayBegin) {
            return moment().weekday(sd_id).format('dddd');
        } else {
            return getSpecialDay(sd_id)?.sd_name;
        }
    };

    const getAccountName = (id) => {
        return getAccount(id)?.name;
    };

    const getDeviceTypeName = (device_type) => {
        switch(device_type) {
            case ASConfig.EnumDeviceType.Controller:
                return intl.formatMessage({id: 'controller'});
            case ASConfig.EnumDeviceType.LPR:
                return intl.formatMessage({id: 'lpr'});
            default:
                return '';
        }
    };

    const getDeviceModelName = (device_type, device_model) => {
        if (device_type === ASConfig.EnumDeviceType.LPR) {
            switch(device_model) {
                case ASConfig.EnumLPRType.AIBox:
                    return 'GV-AIBox';
                default:
                    return '';
            }
        } else {
            switch(device_model) {
                case ASConfig.EnumControllerType.ASBridge:
                    return 'GV-ASBridge';
                case ASConfig.EnumControllerType.AS1620:
                    return 'GV-AS1620';
                case ASConfig.EnumControllerType.CA1320:
                    return 'GV-CA1320';
                default:
                    return '';
            }
        }
    };

    const getDirName = (gate_dir) => {
        switch (gate_dir) {
            case ASConfig.EnumDirectionType.Entry:
                return intl.formatMessage({id: 'entry'});
            case ASConfig.EnumDirectionType.Exit:
                return intl.formatMessage({id: 'exit'});
            default:
                return;
        }
    };

    const getGateName = (device_type, gate_name, gate_dir) => {
        if (device_type === ASConfig.EnumDeviceType.Controller) {
            var dirName = getDirName(gate_dir);
            if (dirName) {
                if (photoViewConfig.gateFormat === ASConfig.EnumGateFormat.GateDevice) {
                    return `${gate_name} (${dirName})`;
                } else {
                    return `${gate_name} ${photoViewConfig.gateSeparator} ${dirName}`;
                }
            }
        }
        return gate_name;
    };

    const getFullGateName = (params) => {   // {gateFormat, separator, device_name, gate_name, gate_dir, dir_name}
        var dirName = getDirName(params.gate_dir);
        if (params.dir_name) {
            dirName = params.dir_name;
        }

        var gateFormat = params.gateFormat,
            gateSeparator = params.separator;
        if (typeof(gateFormat) === 'undefined') {
            gateFormat = photoViewConfig.gateFormat;
        }
        if (typeof(gateSeparator) === 'undefined') {
            gateSeparator = photoViewConfig.gateSeparator;
        }
    
        var gateName = '';
        switch (gateFormat) {
            case ASConfig.EnumGateFormat.DeviceGate:
                gateName = (params.device_name && params.gate_name) ? `${params.device_name} ${gateSeparator} ${params.gate_name}` : (params.device_name || params.gate_name);
                if (dirName) {
                    gateName = `${gateName} ${gateSeparator} ${dirName}`;
                }
                break;
            case ASConfig.EnumGateFormat.GateDevice:
                if (params.device_name && params.gate_name) {
                    if (dirName) {
                        gateName = `${params.gate_name} (${dirName}) ${gateSeparator} ${params.device_name}`;
                    } else {
                        gateName = `${params.gate_name} ${gateSeparator} ${params.device_name}`;
                    }
                } else if (params.device_name || params.gate_name) {
                    if (dirName) {
                        gateName = `${params.device_name || params.gate_name} ${dirName}`;
                    } else {
                        gateName = params.device_name || params.gate_name;
                    }
                }
                break;
            case ASConfig.EnumGateFormat.Gate:
                if (dirName) {
                    gateName = `${params.gate_name} ${gateSeparator} ${dirName}`;
                } else {
                    gateName = params.gate_name;
                }
                break;
            default:
                break;
        }

        return gateName;
    };

    const getCardStatusName = (c_active) => {
        switch(c_active) {
            case ASConfig.EnumCardStatus.Inactive:
                return intl.formatMessage({id: 'card_status_inactive'});
            case ASConfig.EnumCardStatus.Active:
                return intl.formatMessage({id: 'card_status_active'});
            case ASConfig.EnumCardStatus.Active_Lockdown:
                return intl.formatMessage({id: 'card_status_active_lockdown'});
            default:
                return '';
        }
    };

    const getVehicleStatusName = (v_active) => {
        switch(v_active) {
            case ASConfig.EnumVehicleStatus.Inactive:
                return intl.formatMessage({id: 'card_status_inactive'});
            case ASConfig.EnumVehicleStatus.Active:
                return intl.formatMessage({id: 'card_status_active'});
            default:
                return '';
        }
    };

    const getCardCodeName = (value) => {
        switch (value) {
            case ASConfig.EnumCodeFormat.Passcode.code_value:
                return intl.formatMessage({id: 'passcode'});
            case ASConfig.EnumCodeFormat.Wiegand26.code_value:
                return intl.formatMessage({id: 'wiegand26'});
            case ASConfig.EnumCodeFormat.Geo34.code_value:
                return intl.formatMessage({id: 'geo34'});
            case ASConfig.EnumCodeFormat.Geo64.code_value:
                return intl.formatMessage({id: 'geo64'});
            default:
                return '';
        }
    };

    const getAccessAuthModeName = (value) => {
        return value ? intl.formatMessage({id: 'access_rule_card_pin_mode'}) : '';
    }

    const getSpecialDayRuleName = (ruleType) => {
        switch (ruleType) {
            case ASConfig.enumDayRuleType.DateAdd:
                return intl.formatMessage({id: 'specific_date'});
            case ASConfig.enumDayRuleType.Year:
                return intl.formatMessage({id: 'every_year'});
            case ASConfig.enumDayRuleType.MonthWeek:
                return intl.formatMessage({id: 'specific_day'});
            case ASConfig.enumDayRuleType.Month:
                return intl.formatMessage({id: 'every_month'});
            case ASConfig.enumDayRuleType.Week:
                return intl.formatMessage({id: 'every_week'});
            case ASConfig.enumDayRuleType.DateSub:
                return intl.formatMessage({id: 'specific_date_exclude'});
            case '':
                return intl.formatMessage({id: 'preview_all'});
            default:
                return '';
        }
    };

    const getOrdinalWeekName = (week) => {
        if (!Number.isInteger(week)) return '';
        switch (week) {
            case 1:
                return intl.formatMessage({id: 'first'});
            case 2:
                return intl.formatMessage({id: 'second'});
            case 3:
                return intl.formatMessage({id: 'third'});
            case 4:
                return intl.formatMessage({id: 'fourth'});
            default:
                return intl.formatMessage({id: 'last'});
        }
    };

    const getSpecialDayRuleLabel = (ruleType, value) => {
        if (typeof(value) !== 'string') return '';
        if (value.includes('-')) {
            var [start, end] = value.split('-');
            return `${getSpecialDayRuleLabel(ruleType, start)}-${getSpecialDayRuleLabel(ruleType, end)}`;
        }
        switch (ruleType) {
            case ASConfig.enumDayRuleType.DateAdd:
            case ASConfig.enumDayRuleType.DateSub:
                if (value.length !== 6) return;
                return `20${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4, 6)}`;
            case ASConfig.enumDayRuleType.Year:
                if (value.length !== 4) return;
                return `${value.substring(0, 2)}/${value.substring(2, 4)}`;
            case ASConfig.enumDayRuleType.MonthWeek:
                if (value.length !== 4) return;
                return `${moment.months(parseInt(value.substring(0, 2)) - 1)} / ${getOrdinalWeekName(parseInt(value.substring(2, 3)))} / ${moment.weekdays(parseInt(value.substring(3, 4)))}`;
            case ASConfig.enumDayRuleType.Month:
                if (value.length !== 2) return;
                return value;
            case ASConfig.enumDayRuleType.Week:
                if (value.length !== 1) return;
                return moment.weekdays(parseInt(value));
            default:
                return '';
        }
    };

    const getFormatedTime = (value) => {
        if (value) {
            return moment(new Date(value)).format(accountInfo?.time_format || 'MM-DD-YYYY HH:mm:ss');
        }
        return '';
    };

    const addAuditLog = (params) => {   // {msg_id, audit_memo, callback}
        // var callback = params.callback || function() {};

        // ajaxASCloud(Constants.urls.access, {
        //     api_cmd: ASConfig.EnumASCloudAPIType.ADD_AUDIT_LOG,
        //     service_type: serviceType,
        //     msg_id: params.msg_id,
        //     audit_memo: typeof(params.audit_memo) === 'object' ? JSON.stringify(params.audit_memo) : '{}'
        // }, callback);
    };

    return {
        getRegion, getAccessRule, getAlert, getSpecialDay, getAccount,
        getRegionName, getAccessRuleName, getSpecialDayName, getAccountName,
        getDeviceTypeName, getDeviceModelName, getDirName, getGateName, getFullGateName,
        getCardStatusName, getVehicleStatusName, getCardCodeName, getFormatedTime,
        getAccessAuthModeName, getSpecialDayRuleName, getOrdinalWeekName, getSpecialDayRuleLabel,
        addAuditLog
    };
};

export const ASConfigComponent = forwardRef(({...props}, ref) => {
    const ASConfig = useASConfig();
    useImperativeHandle(ref, () => ({
        ...ASConfig
    }));
    return null;
});