/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

import React, { useContext, forwardRef, useImperativeHandle } from "react";

import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { AppContext, Constants } from '../../Utils';

export const useVMSGraphql = () => {
    const context = useContext(AppContext);

    const graphqlLink = createHttpLink({
        uri: `${Constants.gvCloudUrl}graphql`
    });
    
    const QUERY_EVENT_MAPPING = gql`
        query eventMappingQuery {
            query_event_mapping {
                event_id
                event_type_id
            }
        }
    `;

    const QUERY_SINGLE_EVNET = gql`
        query singleEventQuery ($id: Int!) {
            query_single_event(id: $id) {
                id
                device_name
                event_type
                event_id
                event_time
                host_name
                update_user_id
                relay_host_id
                relay_live_path
                relay_rpb_path
                cam
                host_code
                contact_name
                email
                phone
                address
                overview_link
                snapshot_link
                show_playback
                attachment_token
                ex_info
                external_settings
                external_ip
                external_port
                external_vlsvr_port
                external_streaming_port
            }
        }
    `;

    const MUTATION_LOGIN = gql`
        mutation LoginMutation($accountId: Int!, $email: String!, $password: String!, $tz: String) {
            login(
                account_id: $accountId
                email: $email
                password: $password
                tz_id: $tz
                dev: "web"
                skip_recaptcha: "0287978376"
            ) {
                id
                account_id
                name
                email
                master
                language
                token
            }
        }
    `;

    const MUTATION_TEMP_TOKEN = gql`
        mutation SetTempTokenMutation  {
            set_token
        }
    `;

    const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: context.accountInfo.gv_user_token
          }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(graphqlLink),
        cache: new InMemoryCache()
    });

    const queryEventMapping = (callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_EVENT_MAPPING
        })
        .then(data => {
            if (Array.isArray(data?.data?.query_event_mapping)) {
                callback(true, data?.data?.query_event_mapping);
            } else {
                callback(false);
            }
        })
        .catch(() => {
            callback(false);
        });
    };

    const querySingleEvent = (id, callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_SINGLE_EVNET,
            variables: {id}
        })
        .then(data => {
            if (typeof(data?.data?.query_single_event) === 'object') {
                callback(true, data?.data?.query_single_event);
            } else {
                callback(false);
            }
        })
        .catch(() => {
            callback(false);
        });
    };

    const mutateLogin = (accountId, email, password, callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_LOGIN,
            variables: {
                accountId, email, password,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        })
        .then(data => {
            if (typeof(data?.data?.login) === 'object') {
                callback(true, data?.data?.login);
            } else {
                callback(false);
            }
        })
        .catch(() => {
            callback(false);
        });
    };

    const mutationTempToken = (callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_TEMP_TOKEN
        })
        .then(data => {
            if (data?.data?.set_token) {
                callback(true, data?.data?.set_token);
            } else {
                callback(false);
            }
        })
        .catch(() => {
            callback(false);
        });
    };
    
    return {
        queryEventMapping, querySingleEvent, mutateLogin, mutationTempToken
    };
};

class VMSWebSocket extends React.Component {
    _wsKeepAliveTime = 9.5 * 60 * 1000; // Websocket will be disconnected when no transmission for more than 10 minutes.
    _wsReconnectTime = 115 * 60 * 1000; // Websocket will be disconnected by AWS for every 2 hours.
    _wsMaxErrorCount = 20;
    _ws = null;
    _wsKeepAliveTimer = null;
    _wsErrorCount = 0;
    _wsReconnectTimer = null;
    _wsPositiveDisconnect = false;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        this._connectWS();
    }

    componentWillUnmount() {
        clearTimeout(this._wsKeepAliveTimer);
        clearTimeout(this._wsReconnectTimer);
        if (this._ws) {
            this._ws.onopen = null;
            this._ws.onmessage = null;
            this._ws.onclose = null;
            this._ws.onerror = null;
            this._ws.close();
        }
    }

    debugMsg = (module, msg) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            const padZero = (val, length) => {
                return val.toString().padStart(length || 2, '0');
            };
            var now = new Date(),
                time = `${now.getFullYear()}-${padZero(now.getMonth()+1)}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}.${padZero(now.getMilliseconds(), 3)}`;
            console.log(`${time} [${module}]: ${msg}`);
        }
    };

    _debugMsgWS = (msg, send) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            var strSend = send ? 'Send' : (send === false ? 'Receive' : '');
            var strCmd = '';

            try {
                var data = JSON.parse(msg);
                if (data.type) {
                    strCmd = data.type;
                }
            } catch {}

            this.debugMsg('VMSWebSocket', `${strSend}: ${strCmd || ''}, ${msg}`);
        }
    };

    _connectWS = (reconnect) => {
        var _self = this,
            _ws;

        const clearWebsocket = (ws) => {
            ws.onopen = null;
            ws.onmessage = null;
            ws.onclose = null;
            ws.onerror = null;
            ws.close();
            ws = null;
        };

        if (!reconnect && _self._ws) {
            clearWebsocket(_self._ws);
        }

        _self._wsPositiveDisconnect = true;
        _ws = new WebSocket(`${process.env.REACT_APP_URL_GVCLOUD_GLOBAL_WEBSOCKET}?x-token=${_self.props.gv_user_token}&x-token-type=user`);
        
        _ws.onopen = function(e) {
            _self._debugMsgWS('Open');
            _self._wsErrorCount = 0;

            if (reconnect && _self._ws) {
                clearWebsocket(_self._ws);
            }
            _self._ws = _ws;
            _self._wsPositiveDisconnect = false;

            _self.sendWS({
                action: 'SUBSCRIBE',
                authorization: _self.props.gv_user_token
            });
        };

        _ws.onmessage = function(e) {
            _self._debugMsgWS(e.data, false);
            _self._keepWSAlive();
            var data = {};
            try {
                data = JSON.parse(e.data);
            } catch {}

            _self.props.onNotify(data);
        };

        _ws.onclose = function(e) {
            if (!_self._wsPositiveDisconnect) { // if not possive to discconet, then reload page
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self._ws = null;
            _self._debugMsgWS(`Close, wasClean: ${e.wasClean}, code: ${e.code}, reason: ${e.reason}, error count: ${_self._wsErrorCount}`);

            if (_self._wsErrorCount < _self._wsMaxErrorCount) {
                setTimeout(() => {
                    _self._connectWS();
                }, _self._wsErrorCount * 1000);
            } else {
                _self._debugMsgWS(`ReConnect Websocket Fail.`);
                // window.location = `${gvCloudUrl}?redirect=/${enumServicePath[_self.props.serviceType]}&pathname=${window.location.pathname}`;
            }
        };

        _ws.onerror = function(e) {
            _self._ws = null;
            _self._debugMsgWS(`Error, error count: ${_self._wsErrorCount}, message: ${e.message}`);
            _self._wsErrorCount++;
        };

        _self._keepWSAlive();
        
        clearTimeout(_self._wsReconnectTimer);
        _self._wsReconnectTimer = setTimeout(() => {
            _self._connectWS(true);
        }, _self._wsReconnectTime);
    };

    _keepWSAlive = () => {
        clearTimeout(this._wsKeepAliveTimer);
        this._wsKeepAliveTimer = setTimeout(() => {
            this.sendWS({
                action: 'PING'
            }, true);
        }, this._wsKeepAliveTime);
    };

    sendWS = (params) => {
        if (this._ws) {
            var str = JSON.stringify(params);
            this._ws.send(str);
            this._debugMsgWS(str, true);
            this._keepWSAlive();
        }
    };

    render() {
        return null;
    }
}
VMSWebSocket.defaultProps = {
    gv_user_token: '',
    onNotify: function(data) {}
};

const VMSGraphql = forwardRef(({onNotify, ...props}, ref) => {
    // const context = useContext(AppContext);
    const vmsGraphql = useVMSGraphql();
    useImperativeHandle(ref, () => ({
        ...vmsGraphql
    }));
    // wait for vms support ws player
    // return (
    //     !!context.accountInfo.gv_user_token &&
    //     <VMSWebSocket gv_user_token={context.accountInfo.gv_user_token} onNotify={onNotify} />
    // );
    return null;
});
VMSGraphql.defaultProps = {
    onNotify: function(data) {}
};
export default VMSGraphql;