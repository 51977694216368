import React, { useState, useRef, useImperativeHandle, useEffect, useContext, useMemo, Suspense } from 'react';
import { format } from 'react-string-format';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment';

import { useReactToPrint } from 'react-to-print';

import { Form, Select, Checkbox, Slider, Spin, Tooltip, Popover, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Button } from 'react-bootstrap';
import { TiArrowSortedDown,  TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";

import { EnumThemeType, EnumGateFormat, EnumLogType, GetLogMsgFormat, EnumLogMessage, IsWarningMessage, IsNeedSnapshotMessage, 
    EnumWebSocketCmd, EnumSnapshotStatus, EnumCodeFormat, EnumAuditLogMessage } from '../ASUtils/ASConfig';
import { useASConfig } from '../ASUtils/ASUtils';
import { Constants, AppContext, defaultPhotoViewConfig } from '../../Utils';
import { FormatedTime } from '../ASUtils/ASUtils';
import { ASImage, RenderPlateNoPhoto, NoDataMask, LoadingMask } from '../Common/Common';
import { CombineOrganization } from '../../modules/UserSetting/Organization';
import SampleSnapshot from '../../images/snapshot_sample2.svg';
import SampleSnapshotDark from '../../images/snapshot_sample2_dark.svg';
import './LogView.css';
import SVGIcon from '../../icons.js';

const InfoModal = React.lazy(() => import('../../modules/Monitor/InfoModal'));

export const EnumLogViewMode = {
    List: 0,
    Photo: 1
};
export const EnumListViewType = {
    MixLog: -1,
    AccessLog: 0,
    LPRLog: 1,
    SystemLog: 2,
    UserList: 3
};
export const EnumSortDirection = {
    DESC: 0,
    ASC: 1
};

const LogViewRef = React.forwardRef((props, ref) => {
    const ASConfig = useASConfig();
    const intl = useIntl();
    const [infoData, setInfoData] = useState(null);
    const [printData, setprintData] = useState([]);
    const [pageSize, setPageSize] = useState('A4 landscape');
    const [printing, setPrinting] = useState(false);

    const logViewRef = useRef(null);
    const logViewDataRef = useRef(null);

    useEffect(() => {
        if (logViewRef.current) {
            handleScroll(logViewRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.viewMode]);

    useEffect(() => {
        if (printing && props.listViewType === EnumListViewType.SystemLog) {
            handlePrint();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printing, props.listViewType]);

    useImperativeHandle(ref, () => ({
        print(data, pageSize, pageDir) {
            props.onBeforePrint();
            setprintData(data);
            setPageSize(pageSize + ' ' + pageDir);
            setPrinting(true);

            var msg_id;
            switch (props.listViewType) {
                case EnumListViewType.AccessLog:
                    msg_id = EnumAuditLogMessage.ExportAccessLogs; break;
                case EnumListViewType.LPRLog:
                    msg_id = EnumAuditLogMessage.ExportLPRLogs; break;
                case EnumListViewType.SystemLog:
                    msg_id = EnumAuditLogMessage.ExportSystemLogs; break;
                case EnumListViewType.UserList:
                    msg_id = EnumAuditLogMessage.ExportUsers; break;
                default:
                    break;
            }
            if (typeof(msg_id) !== 'undefined') {
                var audit_memo = {
                    export_type: 'PDF',
                    page_size: pageSize,
                    page_dir: intl.formatMessage({id: pageDir === 'landscape' ? 'landscape' : 'portrait'})
                };
                ASConfig.addAuditLog({msg_id, audit_memo});
            }
        },
        scrollToTop() {
            if (logViewRef.current) {
                logViewRef.current.scrollTop = 0;
            }
        }
    }));

    const handleScroll = el => {
        if (el.scrollHeight - el.scrollTop - el.clientHeight < el.clientHeight / 2) {
            props.onScrollToBottom();
        }
    };

    const handleLogClick = log => {
        if (log.log_type !== EnumLogType.System) {
            setInfoData(log);
        }
    };

    const handleAfterPrint = () => {
        setPrinting(false);
        props.onAfterPrint();
    };

    const getPrintFileName = () => {
        var now = new Date();
        const enumListViewFileName = {
            [EnumListViewType.AccessLog]: 'AccessLog',
            [EnumListViewType.LPRLog]: 'LPRLog',
            [EnumListViewType.SystemLog]: 'SystemLog',
        };
        return format('{0}_{1}{2}{3}', enumListViewFileName[props.listViewType] || '',
                    now.getFullYear(), (now.getMonth() + 1).toString().padStart(2, '0'), now.getDate().toString().padStart(2, '0'));
    };

    const handlePrint = useReactToPrint({
        content: () => logViewDataRef.current,
        bodyClass: 'log-view-print',
        documentTitle: getPrintFileName(),
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    return (
        <div ref={logViewRef} className='scroll-box noselect log-view-main' onScroll={e => handleScroll(e.target)} onContextMenu={e => e.preventDefault()}>
        {
            props.data.length === 0 && !props.isLoading && !printing?
            <NoDataMask />
            :
            props.viewMode === EnumLogViewMode.List ?
            <LogListView {...props} onLogClick={handleLogClick} /> : 
            <LogPhotoView {...props} onLogClick={handleLogClick} />
        }
        {
            printing &&
            <LogListView {...props} data={printData} ref={logViewDataRef} isPrint={true} pageSize={pageSize} onAfterRender={handlePrint} />
        }
        {
            infoData &&
            <Suspense fallback={<LoadingMask show={true} />}>
                <InfoModal log={infoData} onClose={() => setInfoData(null)} />
            </Suspense>
        }
        </div>
    );
});

export default class LogView extends React.Component {
    logViewRef = React.createRef();

    print = (...args) => {
        this.logViewRef.current.print(...args);
    };

    scrollToTop = () => {
        this.logViewRef.current.scrollToTop();
    };

    render() {
        return <LogViewRef ref={this.logViewRef} {...this.props} />
    }
}
LogView.defaultProps = {
    data: [],
    viewMode: EnumLogViewMode.Photo,
    listViewType: EnumListViewType.MixLog,
    isLive: false,
    isLoading: false,
    showLoading: false,     // more loading
    onScrollToBottom: function() {},

    sortable: false,
    sortInfo: {
        field: '',
        dir: EnumSortDirection.DESC
    },
    onSort: function(sortInfo) {},
    onLogRightClick: function(e, log) {},

    onBeforePrint: function() {},
    onAfterPrint: function() {}
};


export const GateName = function(props) {
    const ASConfig = useASConfig();
    var dirName = ASConfig.getDirName(props.gate_dir);
    if (props.dir_name) {
        dirName = props.dir_name;
    }

    var gateName = '';
    switch (props.gateFormat) {
        case EnumGateFormat.DeviceGate:
            gateName = (props.device_name && props.gate_name) ? format('{0} {2} {1}', props.device_name, props.gate_name, props.separator) : (props.device_name || props.gate_name);
            if (dirName) {
                gateName = format('{0} {1} {2}', gateName, props.separator, dirName);
            }
            break;
        case EnumGateFormat.GateDevice:
            if (props.device_name && props.gate_name) {
                if (dirName) {
                    gateName = format('{0} ({3}) {2} {1}', props.gate_name, props.device_name, props.separator, dirName);
                } else {
                    gateName = format('{0} {2} {1}', props.gate_name, props.device_name, props.separator);
                }
            } else if (props.device_name || props.gate_name) {
                if (dirName) {
                    gateName = format('{0} ({1})', props.device_name || props.gate_name, dirName);
                } else {
                    gateName = props.device_name || props.gate_name;
                }
            }
            break;
        case EnumGateFormat.Gate:
            if (dirName) {
                gateName = format('{0} {1} {2}', props.gate_name, props.separator, dirName);
            } else {
                gateName = props.gate_name;
            }
            break;
        default:
            break;
    }

    if (props.children) {
        return props.children(gateName);
    } else {
        return gateName;
    }
};
GateName.defaultProps = {
    device_name: '',
    gate_name: '',
    gate_dir: -1,
    gateFormat: EnumGateFormat.DeviceGate,
    separator: '-',
    dir_name: ''
};


class LogBaseView extends React.Component {
    timeInterval = null;

    static contextType = AppContext;

    constructor(props) {
        super(props);

        let t = new Date();

        this.state = {
            currentUTC: t.getTime() + (t.getTimezoneOffset() * 60000)
        };
    }

    componentDidMount() {
        if (this.props.isLive) {
            this.timeInterval = setInterval(() => {
                let t = new Date();
                this.setState({currentUTC: t.getTime() + (t.getTimezoneOffset() * 60000)});
            }, 20 * 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeInterval);
    }
}
LogBaseView.defaultProps = {
    isLive: false
};


/* Photo View*/
const LogPhotoFormatedTime = (props) => {
    const { accountInfo } = useContext(AppContext);

    const timeFormat = useMemo(() => {
        var time_format = accountInfo.time_format,
            index = time_format.indexOf('YYYY');
        if (index === 0) {
            time_format = time_format.substring(5);
        } else if (index > 0) {
            time_format = time_format.substring(0, index - 1) + time_format.substring(index + 4);
        }

        return time_format;
    }, [accountInfo.time_format]);

    return <FormatedTime {...props} timeFormat={timeFormat} />
};


class LogPhotoView extends LogBaseView {
    static plateNoTextId = 'photo-view-plate-no-text-';

    resizeObserver = null;
    viewWidth = -1;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            imageWidth: 0,
            imageHeight: 0
        };

        this.logPhotoViewRef = React.createRef();
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        this.context.addWSNotification(this.receiveNotification);

        if (this.logPhotoViewRef.current) {
            this.resizeObserver = new ResizeObserver(this.handleResize);
            this.resizeObserver.observe(this.logPhotoViewRef.current);
        }
    }

    componentWillUnmount(...args) {
        this.context.removeWSNotification(this.receiveNotification);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        super.componentWillUnmount.apply(this, args);
    }

    receiveNotification = data => {		
		switch (data.cmd_id) {
			case EnumWebSocketCmd.PHOTO_VIEW_CONFIG_CAHNGED:
                this.handleResize(true, data.data1);
				break;
			default:
				break;
		}
	};

    handleResize = (force, includePlate) => {
        var viewWidth = this.logPhotoViewRef.current.getClientRects()[0].width;
        if (viewWidth === this.viewWidth && force !== true) {
            return;
        }
        this.viewWidth = viewWidth;

        if (this.logPhotoViewRef.current.parentElement.offsetHeight >= this.logPhotoViewRef.current.parentElement.scrollHeight) {
            viewWidth = viewWidth - 10; // Preserve scroll bar width, prevent infinite loop
        }

        // x * width + (x-1) * padding = total width
        var rowCnt = Math.ceil((viewWidth + this.context.photoViewConfig.paddingSize) / (this.context.photoViewConfig.imageMaxWidth + this.context.photoViewConfig.paddingSize));     
        
        var imageWidth = (viewWidth - (rowCnt - 1) * this.context.photoViewConfig.paddingSize) / rowCnt,
            imageHeight = imageWidth / this.context.photoViewConfig.imageRatio;
        this.setState({
            imageWidth: imageWidth,
            imageHeight: imageHeight
        });

        if (includePlate === true) {
            var imgs = document.querySelectorAll('.log-photo-view .common-plate-no-photo img[loaded="1"]');
            imgs.forEach(img => {
                var log_type = parseInt(img.getAttribute('log_type')),
                    log_id = parseInt(img.getAttribute('log_id')),
                    log = this.props.data.find(item => item.log_type === log_type && item.log_id === log_id);
                if (log) {
                    RenderPlateNoPhoto(img, log.v_left, log.v_right, log.v_top, log.v_bottom, this.context.photoViewConfig.plateNoWidth, LogPhotoView.plateNoTextId + log.log_id);
                }
            });
        }
    };

    render() {
        return (
            <div ref={this.logPhotoViewRef} className='log-photo-view' style={{
                '--photo-view-item-padding': `${this.context.photoViewConfig.paddingSize}px`,
                '--photo-view-image-width': `${this.state.imageWidth}px`,
                '--photo-view-image-height': `${this.state.imageHeight}px`,
                '--photo-view-photo-size': `${this.context.photoViewConfig.photoSize}px`,
                '--photo-view-font-size': `${this.context.photoViewConfig.fontSize}px`
            }}>
            {
                this.props.data.map(item => {
                    var isNeedSnapshot = IsNeedSnapshotMessage(item.msg_id);
                    return (
                        <div className={`log-photo-item ${IsWarningMessage(item.msg_id)? 'warning' : ''} ${item.snapshot_status === EnumSnapshotStatus.WithImage || item.snapshot_status === EnumSnapshotStatus.HasLarge ? 'with-image' : ''}`} 
                            key={format('{0}-{1}', item.log_type, item.log_id)} onClick={e => this.props.onLogClick(item)}  onContextMenu={e => this.props.onLogRightClick(e, item)}
                        >
                            <div className={`gv-snapshot ${!isNeedSnapshot ? 'no-snapshot' : item.snapshot_status === EnumSnapshotStatus.LackImage ? 'lake-snapshot' : item.snapshot_status === EnumSnapshotStatus.NoLicense ? 'no-license' : ''}`}>
                                {
                                    isNeedSnapshot && (item.snapshot_status === EnumSnapshotStatus.WithImage || item.snapshot_status === EnumSnapshotStatus.HasLarge) &&
                                    <ASImage log_type={item.log_type} log_id={item.log_id} src={item.snapshot_s}
                                        onLoad={e => e.target.classList.add('loaded')}
                                    />
                                }
                                
                                <div className={`log-photo-item-image-tip`}>
                                    <LogPhotoFormatedTime localtime={item.localtime} utctime={item.utc} dst={item.dst} fixedTime={this.context.photoViewConfig.fixedTimestamp || !this.props.isLive}>
                                    {
                                        (time, countdownTime) => (
                                            <div className='log-time' data-localtime={time}>
                                                <div>{countdownTime}</div>
                                            </div>
                                        )
                                    }
                                    </LogPhotoFormatedTime>
                                    
                                </div>
                                {
                                    (item.log_type === EnumLogType.LPR && item.v_no && item.snapshot_s && IsNeedSnapshotMessage(item.msg_id)) &&
                                    <div className='common-plate-no-photo'>
                                        <ASImage log_type={item.log_type} log_id={item.log_id} src={item.snapshot_s}
                                            onLoad = {e => {
                                                e.target.setAttribute('loaded', '1');
                                                RenderPlateNoPhoto(e.target, item.v_left, item.v_right, item.v_top, item.v_bottom, this.context.photoViewConfig.plateNoWidth, LogPhotoView.plateNoTextId + item.log_id);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className={'log-photo-item-content'}>
                                {
                                    this.context.photoViewConfig.photoSize > 0 &&
                                    <div className='log-photo-item-photo gv-user-avatar'>
                                    {
                                        item.u_photo_s &&
                                        <ASImage u_user_id={item.u_user_id} src={item.u_photo_s}
                                            onLoad={e => e.target.classList.add('loaded')}
                                        />
                                    }
                                    </div>
                                }
                                <div className='log-photo-item-description'>
                                    <div className='user-name'>{item.u_name}&nbsp;</div>
                                    <div className='message'>
                                        <FormattedMessage {...GetLogMsgFormat(item.msg_id)} />&nbsp;
                                    </div>
                                    <div>
                                        <GateName {...item} gateFormat={this.context.photoViewConfig.gateFormat} separator={this.context.photoViewConfig.gateSeparator} />
                                    </div>
                                </div>
                                {
                                    item.log_type === EnumLogType.LPR && item.v_no &&
                                    <div className='common-plate-no-text' id={LogPhotoView.plateNoTextId + item.log_id}>
                                        <span>{item.v_no}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })
            }
            {
                this.props.showLoading &&
                <div className='log-photo-view-loading'>
                    <Spin />
                </div>
            }
            </div>
        );
    }
}
LogPhotoView.defaultProps = {
    data: [],
    onLogClick: function(log) {},
    onLogRightClick: function(e, log) {},
    showLoading: false
};

/* List View */
export const ListColumns = function(_dataIndex, options) {
    this.dataIndex = _dataIndex;

    this.title = '';
    this.width = null;
    this.sortable = false;
    this.renderer = null;
    switch (_dataIndex) {
        case 'snapshot':
            this.title = <FormattedMessage id = "field_snapshot" defaultMessage = "IP cam Snapshot"/>;
            this.width = 120;
            this.renderer = (row, listView) => {
                var isNeedSnapshot = IsNeedSnapshotMessage(row.msg_id);
                return (
                    <div className={`gv-snapshot ${!isNeedSnapshot ? 'no-snapshot' : row.snapshot_status === EnumSnapshotStatus.LackImage ? 'lake-snapshot' : row.snapshot_status === EnumSnapshotStatus.NoLicense ? 'no-license' : ''}`}>
                        <ASImage log_type = {row.log_type} log_id = {row.log_id} src = {row.snapshot_s}
                            onLoad={e => {e.target.classList.add('loaded'); listView.handleImageRendered(1);}}
                            onError={() => listView.handleImageRendered(1)}
                        />
                    </div>
                );
            };
            break;
        case 'msg_id':
            this.title = <FormattedMessage id = "field_message" defaultMessage = "Message"/>;
            this.sortable = true;
            this.renderer = (row) => {
                return (
                    <div className='log-list-table-cell'>
                        <FormattedMessage {...GetLogMsgFormat(row.msg_id)} />
                    </div>
                );
            };
            break;
        case 'u_name':
            this.title = <FormattedMessage id = "field_user" defaultMessage = "User"/>;
            this.sortable = true;
            this.renderer = (row, listView) => {
                return (
                    <div className='log-list-table-cell user-photo'>
                        <div className='gv-user-avatar log-list-table-photo'>
                            <ASImage log_type='user' u_user_id={row.u_user_id} src={row.u_photo_s}
                                onLoad={e => {listView.handleImageRendered(0); e.target.classList.add('loaded');}} 
                                onError={() => listView.handleImageRendered(0)}
                            />
                        </div>
                        
                        <span>{row.u_name}</span>
                    </div>
                );
            };
            break;
        case 'c_no':
            this.title = <FormattedMessage id = "field_card" defaultMessage = "Card No."/>;
            this.sortable = true;
            this.renderer = (row) => {
                return (
                    <div className='log-list-table-cell'>
                        {row.c_code === EnumCodeFormat.Passcode.code_value ? <FormattedMessage id='passcode' /> : row.c_no}
                    </div>
                );
            };
            break;
        case 'v_no':
            this.title = <FormattedMessage id = "license_plate" defaultMessage = "License Plate"/>;
            this.sortable = true;
            break;
        case 'c_v_no':
            // this.title = <FormattedMessage id = "field_card_plate" defaultMessage = "Card / Plate"/>;
            this.title = <FormattedMessage id = "field_card" defaultMessage = "Card No."/>;
            this.width = 110;
            this.renderer = (row) => {
                return (
                    <div className='log-list-table-cell'>
                        {row.log_type === EnumLogType.Access ? row.c_code === EnumCodeFormat.Passcode.code_value ? <FormattedMessage id='passcode' /> : row.c_no : row.v_no}
                    </div>
                );
            };
            break;
        case 'plate_photo':
            this.title = <FormattedMessage id = "field_plate_photo" defaultMessage = "Plate Photo"/>;
            this.renderer = (row, listView) => {
                return (
                    (row.log_type === EnumLogType.LPR && row.v_no && row.snapshot_s) &&
                    <div className='common-plate-no-photo'> 
                        <ASImage log_type={row.log_type} log_id={row.log_id} src={row.snapshot_s}
                            onLoad = {e => {RenderPlateNoPhoto(e.target, row.v_left, row.v_right, row.v_top, row.v_bottom, 85); listView.handleImageRendered(2);}}
                            onError={() => listView.handleImageRendered(2)}
                        />
                    </div>
                );
            };
            break;
        case 'u_id':
            this.title = <FormattedMessage id = "field_id" defaultMessage = "ID"/>;
            this.sortable = true;
            break;
        case 'u_email':
            this.title = <FormattedMessage id = "field_email" defaultMessage = "E-mail"/>;
            // this.sortable = true;
            break;
        case 'u_phone':
            this.title = <FormattedMessage id = "field_phone" defaultMessage = "Phone"/>;
            this.sortable = true;
            break;
        case 'gate':
        case 'gate_name':
            this.title = <FormattedMessage id = "field_gate" defaultMessage = "Gate"/>;
            this.sortable = false;
            this.renderer = (row, listView) => {
                return (
                    <div className='log-list-table-cell'>
                        <GateName {...row} gateFormat={listView.context.photoViewConfig.gateFormat} separator={listView.context.photoViewConfig.gateSeparator} />
                    </div>
                );
            };
            break;
        case 'localtime':
            this.title = <FormattedMessage id = "field_time" defaultMessage = "Time"/>;
            this.sortable = true;
            this.width = 190;
            this.renderer = (row, listView) => {
                return (
                    <FormatedTime localtime={row.localtime} utctime={row.utc} dst={row.dst} fixedTime={listView.context.photoViewConfig.fixedTimestamp || !listView.props.isLive}>
                    {
                        (time, countdownTime) => (
                            <div className='log-list-table-cell log-time' data-localtime={time}>
                                <span>{countdownTime}</span>
                            </div>
                        )
                    }
                    </FormatedTime>
                );
            };
            break;
        case 'lane_dir':
            this.title = <FormattedMessage id = "lane_dir" defaultMessage = "Direction"/>;
            this.sortable = true;
            this.renderer = (row) => {
                return (
                    <div className='log-list-table-cell'>
                    {
                        (row.lane_dir ===0) ?
                        <FormattedMessage id = "entry" defaultMessage = "Entry"/> :
                        <FormattedMessage id = "exit" defaultMessage = "Exit"/>
                    }
                    </div>
                );
            };
            break;
        case 'msg_data':
            this.title = <FormattedMessage id = "data" defaultMessage = "Data"/>;
            this.sortable = true;
            break;
        case 'op':
            this.title = <FormattedMessage id = "operator" defaultMessage = "Operator"/>;
            this.sortable = true;
            break;
        case 'ip':
            this.title = <FormattedMessage id = "ip_address" defaultMessage = "IP Address"/>;
            this.sortable = true;
            break;
        case 'u_og':
            this.title = <FormattedMessage id = "organization" defaultMessage = "Organization"/>;
            this.sortable = true;
            this.renderer = (row) => {
                return (
                    <div className='log-list-table-cell'>
                        <CombineOrganization {...row} />
                    </div>
                );
            };
            break;
        default:
            break;
    }

    options = {...options};
    if (typeof(options.title) !== 'undefined') {
        this.title = options.title;
    }
    if (typeof(options.width) !== 'undefined') {
        this.width = options.width;
    }
    if (typeof(options.sortable) !== 'undefined') {
        this.sortable = options.sortable;
    }
    if (typeof(options.renderer) !== 'undefined') {
        this.renderer = options.renderer;
    }
};

const getListColumns = (listViewType) => {
    switch (listViewType) {
        case EnumListViewType.AccessLog:
            return [
                new ListColumns('snapshot'),
                new ListColumns('u_name'),
                new ListColumns('msg_id', {sortable: false}),
                new ListColumns('gate_name', {title: <FormattedMessage id = "field_door" defaultMessage = "Door"/>}),
                new ListColumns('u_id'),
                new ListColumns('c_no'),
                new ListColumns('u_og', {sortable: false}),
                new ListColumns('localtime')
            ];
        case EnumListViewType.LPRLog:
            return [
                new ListColumns('snapshot'),
                new ListColumns('u_name'),
                new ListColumns('msg_id'),
                new ListColumns('gate_name', {title: <FormattedMessage id = "field_lane" defaultMessage = "Lane"/>}),
                new ListColumns('lane_dir'),
                new ListColumns('v_no'),
                new ListColumns('plate_photo'),
                new ListColumns('u_id'),
                new ListColumns('localtime')
            ];
        case EnumListViewType.MixLog:
            return [
                new ListColumns('snapshot'),
                new ListColumns('u_name'),
                new ListColumns('msg_id'),
                new ListColumns('gate'),
                new ListColumns('c_v_no'),
                // new ListColumns('plate_photo'),
                new ListColumns('localtime')
            ];
        case EnumListViewType.SystemLog:
            return [
                new ListColumns('msg_id', {sortable: false}),
                new ListColumns('msg_data'),
                new ListColumns('op'),
                new ListColumns('ip'),
                new ListColumns('localtime')
            ];
        default:
            return [];
    }
};

export class LogListView extends LogBaseView {
    dataCount = 0;
    platePhotoCount = 0;
    loadedPhotoCount = 0;
    loadedSnapshotCount = 0;
    loadedPlatePhotoCount = 0;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            columns: getListColumns(props.listViewType)
        };

        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);

        if (this.props.isPrint) {
            if (this.props.listViewType === EnumListViewType.SystemLog) {
                this.props.onAfterRender();
            } else {
                this.dataCount = this.props.data.length;
                this.platePhotoCount = this.props.data.filter(row => row.log_type === EnumLogType.LPR && row.v_no && row.snapshot_s).length;
    
                this.loadedPhotoCount = 0;
                this.loadedSnapshotCount = 0;
                this.loadedPlatePhotoCount = 0;
            }
        }
    }

    componentWillUnmount(...args) {
        super.componentWillUnmount.apply(this, args);
    }

    getHeader = col => {
        if (typeof(col.title) === 'function') {
            return col.title();
        } else if (this.props.sortable && col.sortable) {
            return (
                <div className={'log-list-table-sort-header' + 
                    (this.props.sortInfo.field === col.dataIndex ? ' focus' : '')}
                    data-index={col.dataIndex} onClick={this.handleSort}
                >
                    <span>{col.title}</span>
                    {
                        (this.props.sortInfo.field === col.dataIndex && this.props.sortInfo.dir === EnumSortDirection.DESC) ?
                        <TiArrowSortedDown /> :
                        (this.props.sortInfo.field === col.dataIndex && this.props.sortInfo.dir === EnumSortDirection.ASC) ?
                        <TiArrowSortedUp /> : <TiArrowUnsorted />
                    }
                </div>
            );
        } else {
            return <div>{col.title}</div>;
        }
    };

    handleSort = e => {
        var dataIndex = e.target.closest('.log-list-table-sort-header').getAttribute('data-index');
        var sortInfo = this.props.sortInfo;
        if (sortInfo.field === dataIndex) {
            if (sortInfo.dir === EnumSortDirection.DESC) {
                sortInfo.dir = EnumSortDirection.ASC;
            } else {
                sortInfo.dir = EnumSortDirection.DESC;
            }
        } else {
            sortInfo = {
                field: dataIndex,
                dir: dataIndex === 'localtime' ? EnumSortDirection.DESC : EnumSortDirection.ASC
            };
        }
        this.props.onSort(sortInfo);
    };

    handleImageRendered = (type) => {   // type: 0: photo, 1: snapshot, 2: plate Photo
        if (this.props.isPrint) {
            if (type === 0) {
                this.loadedPhotoCount++;
            } else if (type === 1) {
                this.loadedSnapshotCount++;
            } else {
                this.loadedPlatePhotoCount++;
            }
            if (this.loadedPhotoCount === this.dataCount && this.loadedSnapshotCount === this.dataCount && this.loadedPlatePhotoCount === this.platePhotoCount) {
                this.props.onAfterRender();
            }
        }
    };

    render() {
        return (
            <div className={'log-list-view ' + (this.props.isPrint? 'log-view-print-wrap' : '')}>
                <style type="text/css" media="print">{` @page { size: ${this.props.pageSize}; }`}</style>
                <table className='log-list-table'>
                    <colgroup>
                    {
                        this.state.columns.map(item =>
                            <col key={item.dataIndex} style={{width: item.width ? (item.width + 'px') : ''}} />
                        )
                    }
                    </colgroup>
                    <thead>
                        <tr>
                        {
                            this.state.columns.map(item =>
                                <th key={item.dataIndex}>
                                { this.getHeader(item) }
                                </th>
                            )
                        }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.data.map(row => {
                            var key = this.props.listViewType === EnumListViewType.UserList ?
                                        format('{0}', row.u_user_id) :
                                        format('{0}-{1}', row.log_type, row.log_id)
                            return (
                                <tr key={key} className={IsWarningMessage(row.msg_id) ? 'warning' : ''}
                                    onClick={e => this.props.onLogClick(row)} onContextMenu={e => this.props.onLogRightClick(e, row)}
                                >
                                {
                                    this.state.columns.map(col =>
                                        <td key={col.dataIndex}>
                                        {
                                            col.renderer ? col.renderer(row, this) :
                                            (
                                                <div className='log-list-table-cell'>
                                                    {row[col.dataIndex]}
                                                </div>
                                            )
                                        }
                                        </td>
                                    )
                                }
                                </tr>
                            );
                        })
                    }
                    </tbody>
                    <tfoot>
                    {
                        this.props.showLoading &&
                        <tr>
                            <td colSpan={this.state.columns.length}>
                                <div className='log-list-view-loading'>
                                    <Spin />
                                </div>
                            </td>
                        </tr>
                    }
                    </tfoot>
                </table>
            </div>
        );
    }
}
LogListView.defaultProps = {
    data: [],
    listViewType: EnumListViewType.MixLog,
    onLogClick: function(log) {},
    onLogRightClick: function(e, log) {},
    showLoading: false,

    sortable: false,
    sortInfo: {
        field: '',
        dir: EnumSortDirection.DESC
    },
    onSort: function(sortInfo) {},

    isPrint: false,
    pageSize: 'A4 landscape',
    onAfterRender: function() {}
};


/* Log View Setting */
export const LogViewSetting = function(props) {
    const momentNow = new moment();
    // const samplePlatePos = [433, 575, 635, 752];
    const enumGateSeparator = [
        '/',
        '-',
        '.',
        ':'
    ];
    const context = useContext(AppContext);
    const intl = useIntl();
    const formRef = React.createRef();
    const [form] = Form.useForm();

    const gateSeparator = Form.useWatch('gateSeparator', form);
    const gateFormat = Form.useWatch('gateFormat', form);
    const fixedTimestamp = Form.useWatch('fixedTimestamp', form);
    const paddingSize = Form.useWatch('paddingSize', form);
    const imageMaxWidth = Form.useWatch('imageMaxWidth', form);
    const imageRatio = Form.useWatch('imageRatio', form);
    const photoSize = Form.useWatch('photoSize', form);
    const fontSize = Form.useWatch('fontSize', form);
    // const plateNoWidth = Form.useWatch('plateNoWidth', form);
    const [openModal, setOpenModal] = useState(true);

    const imageMaxHeight = useMemo(() => {
        return imageMaxWidth / imageRatio;
    }, [imageMaxWidth, imageRatio]);

    useEffect(() => {
        if (form) {
            form.setFieldsValue(context.photoViewConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (photoSize > 0 && photoSize < 30) {
            form.setFieldValue('photoSize', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photoSize]);

    const handleLoadDefault = () => {
        form.setFieldsValue(defaultPhotoViewConfig);
    };

    const handleClose = () => {
        setOpenModal(false) ;
        setTimeout(props.onClose, 300);
    };

    const handleOk = () => {
        context.setPhotoViewConfig(form.getFieldsValue());
        handleClose();
    };

    return (
        <Modal centered width={1140} className='log-view-setting-modal' open={openModal} onCancel={handleClose}
            title={<FormattedMessage id="log_view_setting" defaultMessage="Log Display Setting" />}
            footer={[
                <Button variant="secondary" className='action' onClick={handleLoadDefault}>
                    <FormattedMessage id="default" defaultMessage="Default" />
                </Button>,
                <div className='space'></div>,
                <Button variant="secondary" className='action' onClick={handleClose}>
                    <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>,
                <Button className='action' onClick={handleOk}>
                    <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
            ]}
        >
            <div className='log-view-setting-form-wrap'>
                <Form ref={formRef} form={form} name='log-view-settings-form' className='log-view-setting-form' initialValues={context.photoViewConfig} 
                    labelCol={{span: 8}} colon={false}
                >
                    <div className='log-view-setting-form-frame global'>
                        <Form.Item labelCol={{span: 6}} label={<FormattedMessage id='gate_format' />} className='log-view-setting-form-inline'>
                            <Form.Item name='gateSeparator' style={{width: '60px'}}>
                                <Select className='as-ant-select'>
                                {
                                    enumGateSeparator.map((separator, idx) =>
                                        <Select.Option key={idx} value={separator}>{separator}</Select.Option>
                                    )
                                }
                                </Select>
                            </Form.Item>
                            <Form.Item name='gateFormat' className="fill right">
                                <Select className='as-ant-select'>
                                {
                                    Object.keys(EnumGateFormat).map((key) => {
                                        var format = EnumGateFormat[key];
                                        const params = {
                                            device_name: intl.formatMessage({id: 'device'}),
                                            gate_name: intl.formatMessage({id: 'gate'}),
                                            dir_name: intl.formatMessage({id: 'direction'}),
                                            gateFormat: format,
                                            separator: gateSeparator
                                        };
                                        return (
                                            <Select.Option key={format} value={format}><GateName key={key} {...params} /></Select.Option>
                                        );
                                    })
                                }
                                </Select>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item labelCol={{span: 0}} name='fixedTimestamp' label={<>&nbsp;</>} colon={false} valuePropName="checked">
                            <Checkbox><FormattedMessage id='fixed_timestamp' /></Checkbox>
                        </Form.Item>
                    </div>
                    <div className='log-view-setting-form-frame'>
                        <span><FormattedMessage id="photo_view" defaultMessage="Photo View" /></span>

                        <Form.Item label={<FormattedMessage id='snapshot_max_width' />} className='log-view-setting-form-inline'>
                            <Form.Item name='imageMaxWidth' className="fill">
                                <Slider min={300} max={500} step={50} tooltip={{formatter: (value) => `${value}px`}} />
                            </Form.Item>
                            <span>{`${imageMaxWidth}px`}</span>
                        </Form.Item>
                        <Form.Item name='imageRatio' label={<FormattedMessage id='snapshot_ratio' />}>
                            <Select className='as-ant-select'>
                                <Select.Option value={16/9}>16 : 9</Select.Option>
                                <Select.Option value={16/10}>16 : 10</Select.Option>
                                <Select.Option value={4/3}>4 : 3</Select.Option>
                                <Select.Option value={3/2}>3 : 2</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='padding_size' />} className='log-view-setting-form-inline'>
                            <Form.Item name='paddingSize' className="fill">
                                <Slider min={0} max={60} step={2} tooltip={{formatter: (value) => `${value}px`}} />
                            </Form.Item>
                            <span>{`${paddingSize}px`}</span>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='photo_size' />} className='log-view-setting-form-inline'>
                            <Form.Item name='photoSize' className="fill">
                                <Slider min={0} max={120} step={6} tooltip={{formatter: (value) => `${value}px`}} />
                            </Form.Item>
                            <span>{`${photoSize}px`}</span>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='font_size' />} className='log-view-setting-form-inline'>
                            <Form.Item name='fontSize' className="fill">
                                <Slider min={12} max={24} step={1} tooltip={{formatter: (value) => `${value}px`}} />
                            </Form.Item>
                            <span>{`${fontSize}px`}</span>
                        </Form.Item>
                    </div>
                </Form>
            </div>

            <div className='log-view-setting-preview'style={{
                '--photo-view-item-padding': `${paddingSize}px`,
                '--photo-view-image-width': `${imageMaxWidth}px`,
                '--photo-view-image-height': `${imageMaxHeight}px`,
                '--photo-view-photo-size': `${photoSize}px`,
                '--photo-view-font-size': `${fontSize}px`
            }}>
                <div className='log-view-setting-preview-warp'>
                    <div className='log-photo-item with-image'>
                        <div className='gv-snapshot'>
                            <ASImage src={context.accountInfo.theme === EnumThemeType.Dark ? SampleSnapshotDark : SampleSnapshot} className='loaded' />
                            <div className={`log-photo-item-image-tip`}>
                                <LogPhotoFormatedTime localtime={momentNow.format('YYYY-MM-DD HH:mm:ss')}>
                                {
                                    (time) => (
                                        <div className='log-time' data-localtime={time}>
                                            <div>
                                                {
                                                    fixedTimestamp ?
                                                    time
                                                    :
                                                    <FormattedMessage id = "seconds_ago" defaultMessage = "a few seconds ago"/>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                </LogPhotoFormatedTime>
                            </div>
                            {/* {
                                <div className='common-plate-no-photo'> 
                                    <ASImage src={SampleSnapshot}
                                        onLoad = {e => RenderPlateNoPhoto(e.target, samplePlatePos, plateNoWidth, 'log-photo-view-plate-no-text')}
                                    />
                                </div>
                            } */}
                        </div>
                        <div className={'log-photo-item-content'}>
                            {
                                photoSize > 0 &&
                                <div className='log-photo-item-photo gv-user-avatar'></div>
                            }
                            <div className='log-photo-item-description'>
                                <div className='user-name'><FormattedMessage id="user" defaultMessage="User Name" /></div>
                                <div className='message'>
                                    <FormattedMessage {...EnumLogMessage[0].intlFormat} />&nbsp;
                                </div>
                                <div>
                                    <GateName device_name={intl.formatMessage({id: 'device_name', defaultMessage: 'Device Name'})}
                                        gate_name={intl.formatMessage({id: 'gate_name', defaultMessage: 'Gate Name'})}
                                        dir_name={intl.formatMessage({id: 'direction', defaultMessage: 'Direction'})}
                                        gateFormat={gateFormat} separator={gateSeparator}
                                    />
                                </div>
                            </div>
                            {/* {
                                <div className='common-plate-no-text' id='log-photo-view-plate-no-text'>
                                    <span>GEO-1234</span>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
LogViewSetting.defaultProps = {
    onClose: function() {}
};

export const ExportDropdownBtn = ({onClick, ...props}) => {
    const [form] = Form.useForm();
    const exportType = Form.useWatch('exportType', form);
    const pageSize = Form.useWatch('pageSize', form);
    const pageDir = Form.useWatch('pageDir', form);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                exportType: window.localStorage.getItem(Constants.storageNames.exportType) || 'pdf',
                pageSize: window.localStorage.getItem(Constants.storageNames.printPageSize) || 'A4',
                pageDir: window.localStorage.getItem(Constants.storageNames.printPageDir) || 'landscape'
            });
        }
    }, [form]);

    const handleClick = e => {
        window.localStorage.setItem(Constants.storageNames.exportType, exportType);
        window.localStorage.setItem(Constants.storageNames.printPageSize, pageSize);
        window.localStorage.setItem(Constants.storageNames.printPageDir, pageDir);
        setOpen(false);
        onClick(exportType, pageSize, pageDir);
    };

    return (
        <Popover overlayClassName='export-dropdown' placement='bottom' arrowPointAtCenter={true} trigger={['click']} open={open} onOpenChange={(open) => setOpen(open)}
            content={
                <Form form={form} labelCol={{span: 0}}>
                    <Form.Item name='exportType'>
                        <Select className='as-ant-select'>
                            <Select.Option value="excel">Excel</Select.Option>
                            <Select.Option value="pdf">PDF</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='pageSize' hidden={exportType !== 'pdf'}>
                        <Select className='as-ant-select'>
                            <Select.Option value="A1">A1</Select.Option>
                            <Select.Option value="A2">A2</Select.Option>
                            <Select.Option value="A3">A3</Select.Option>
                            <Select.Option value="A4">A4</Select.Option>
                            <Select.Option value="A5">A5</Select.Option>
                            <Select.Option value="A6">A6</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='pageDir' hidden={exportType !== 'pdf'}>
                        <Select className='as-ant-select'>
                            <Select.Option value="landscape"><FormattedMessage id="landscape" defaultMessage="Landscape" /></Select.Option>
                            <Select.Option value="portrait"><FormattedMessage id="portrait" defaultMessage="Portrait" /></Select.Option>
                        </Select>
                    </Form.Item>
                    <Button size="sm" variant="primary" className='action' onClick={handleClick}>
                        <FormattedMessage id="export" defaultMessage="Export" />
                    </Button>
                    {
                        props.count > Constants.maxExportPdfCount && exportType === 'pdf' &&
                        <span><FormattedMessage id='maximum_export_pdf_format' values={{0: Constants.maxExportPdfCount}} /></span>
                    }
                </Form>     
            }
        >
            <Tooltip placement='bottom' title={<FormattedMessage id="export" defaultMessage="Export" />}>
                <SVGIcon.Export className='icon-btn' />
            </Tooltip>
        </Popover>
    );
};
ExportDropdownBtn.defaultProps = {
    count: 0,
    onClick: function(exportType, pageSize, pageDir) {}
};

// Log View Filter
export const LogViewFilterForm = ({querying, disabledSearch, disabledReset, onSearch, onReset, ...props}) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') 
            onSearch();
    };

    return (
        <form className='log-view-filter-form' onKeyPress={handleKeyPress}>
            <div className='main'>
                {props.children}
            </div>
            <div className='buttons'>
                <Tooltip placement='bottom' title={<FormattedMessage id="search" defaultMessage="Search" />}>
                    <button type="button" onClick={onSearch} disabled={querying || disabledSearch}> 
                        {querying ? 
                            <Spin size='small' indicator={<LoadingOutlined spin />} /> : <SVGIcon.Search/>
                        }
                    </button>
                </Tooltip>
                <Tooltip placement='bottom' title={<FormattedMessage id="reset" defaultMessage="Reset" />}>
                    <button type="button" onClick={onReset} disabled={querying || disabledReset}>
                        <SVGIcon.SearchClear/>
                    </button>
                </Tooltip>
            </div>
        </form>
    );
};
LogViewFilterForm.defaultProps = {
    querying: false,
    disabledSearch: false,
    disabledReset: false,
    onSearch: function(e) {},
    onReset: function(e) {}
};