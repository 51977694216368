import React, { useState, useEffect, useRef, useMemo, forwardRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { format } from 'react-string-format';
import { Modal, Segmented, Empty, Tooltip, Spin, Input, Popover, Dropdown, Form } from 'antd';
import { Button, Card } from 'react-bootstrap';
import { BsExclamationTriangleFill, BsFillQuestionCircleFill, BsCheckLg, BsXCircleFill } from "react-icons/bs";
import { ExcludeProps } from '../../Utils'
import { EnumLogViewMode } from '../Common/LogView';
import SVGIcon from '../../icons.js';
import './Common.css';
import EmptySnapshot from '../../images/snapshot_empty.svg';
import EmptyUserPhoto  from '../../images/user_noimg.svg';

export function ShowAlertMessage({type, msg, show, doubleComfirm, onClose, btn_text_close, btn_text_ok, ...props}) {
    const msgIcon = useMemo(() => {
        switch (type) {
            case 'warning':
                return <div className='commom-msg-icon warning'><BsExclamationTriangleFill /></div>;
            case 'success':
                return <div className='commom-msg-icon success'><BsCheckLg /></div>;
            case 'error':
                return <div className='commom-msg-icon error'><BsXCircleFill /></div>;
            case 'question':
                return <div className='commom-msg-icon question'><BsFillQuestionCircleFill /></div>;
            default:
                return null;
        }
    }, [type]);

    const [confirm, setConfirm] = useState('');

    return (
        <Modal className='common-alert-modal' width={500} {...props} centered open={show} onCancel={() => onClose(false)} onOk={() => onClose(true)}
            cancelText={type === 'question' ? <FormattedMessage id="cancel" defaultMessage="Cancel" /> : btn_text_close}
            okText={btn_text_ok}
            okButtonProps={{hidden: type !== 'question', disabled: doubleComfirm && type === 'question' && confirm !== 'DELETE'}}
        >
            <div className='common-alert-body scroll-box'>
                { msgIcon }
                <span>{msg}</span>
            </div>
            {
                doubleComfirm && type === 'question' &&
                <Form colon={false} layout='vertical' className='common-alert-form'>
                    <Form.Item name='confirm_input' label={<FormattedMessage id='double_confirm_delete' />} value={confirm} onChange={e => setConfirm(e.target.value)}>
                        <Input placeholder='DELETE' />
                    </Form.Item>
                </Form>
            }
        </Modal>
    );
}
ShowAlertMessage.defaultProps = {
    show: false,
    title: '',
    msg: '',
    type: 'warning',   // warning, success, error, question
    doubleComfirm: false,
    btn_text_ok: <FormattedMessage id="ok" defaultMessage="OK" />,    // Only for type === 'question'
    btn_text_close: <FormattedMessage id="close" defaultMessage="Close" />,
    onClose: function() {},  // return true/false
};

export function LoadingMask(props) {
    return (
        <div className={'common-mask' + (props.show? '' : ' hide') + (props.transparentBackground? ' transparent' : '')}>
            <Spin size='large' tip={props.text} />
        </div>
    );
}
LoadingMask.defaultProps = {
    show: false,
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    transparentBackground: false
};

export function NoDataMask(props) {
    return (
        <Empty className='common-no-data' description={<FormattedMessage id='no_data' defaultMessage='No data' />} image={<SVGIcon.NoData />}>
        { props.children }
        </Empty>
    );
}

export function ProgressBarMask(props) {
    return (
        <div className={'common-mask' + (props.show? '' : ' hide')}>
            <Spin size='large' tip={props.text} />
            <Button onClick = {props.onClose}>{props.btn_text}</Button>
        </div>
    );
}
ProgressBarMask.defaultProps = {
    show: false,
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    btn_text: <FormattedMessage id="cancel" defaultMessage="Cancel" />,
    onClose: function() {}
}

const ASImageCacheMap = {
    '0': {},    // Access Log, by log_id
    '1': {},    // LPR Log, by log_id
    'user': {}  // User Photo, by u_user_id
};
export function ASImage(props) {
    const [imgSrc, setImgSrc] = useState(null);
    const [status, setStatus] = useState(0);   // -1: Ignore, 0: Use cache imag, 1: Use parameter image, 2: Image Load Error, 3: force Use Src Image

    useEffect(() => {
        var cacheImg;
        if (props.force && props.src) {
            setStatus(3);
            setImgSrc(props.src);
            return;
        } else if (props.u_user_id > -1) {
            if (props.u_user_id === 0) {
                cacheImg = EmptyUserPhoto;
                setStatus(2);
            } else {
                cacheImg = ASImageCacheMap['user'][props.u_user_id.toString()];
            }
        } else if (props.log_type > -1 && props.log_id > -1) {
            cacheImg = ASImageCacheMap[props.log_type.toString()] ? ASImageCacheMap[props.log_type.toString()][props.log_id.toString()] : null;
        } else {
            if (props.src) {
                setStatus(-1);
                setImgSrc(props.src);
            } else {
                setStatus(2);
                setImgSrc(props.error_image || EmptySnapshot);
            }
            return;
        }

        if (cacheImg) {
            setImgSrc(cacheImg);
        } else if (props.src) {
            setStatus(1);
            setImgSrc(props.src);
        } else {
            setStatus(2);
            if (props.u_user_id > -1) {
                setImgSrc(props.error_image || EmptyUserPhoto);
            } else {
                setImgSrc(props.error_image || EmptySnapshot);
            }
        }
    }, [props.u_user_id, props.log_type, props.log_id, props.src, props.force, props.error_image]);

    const handleLoad = e => {
        if (status === 1 || status === 3) {
            if (props.u_user_id > -1) {
                ASImageCacheMap['user'][props.u_user_id.toString()] = e.target.src;
            } else if (props.log_type > -1 && props.log_id > -1) {
                ASImageCacheMap[props.log_type.toString()][props.log_id.toString()] = e.target.src;
            }
        }
        if (status === 2) {
            props.onError(e);
        } else {
            props.onLoad(e);
        }
    };

    const handleError = e => {
        if (status === 0) {
            if (props.u_user_id > -1) {
                delete ASImageCacheMap['user'][props.u_user_id.toString()];
            } else if (props.log_type > -1 && props.log_id > -1) {
                delete  ASImageCacheMap[props.log_type.toString()][props.log_id.toString()];
            }

            if (props.src) {
                setStatus(1);
                setImgSrc(props.src);
            } else {
                setStatus(-1);
                if (props.u_user_id > -1) {
                    setImgSrc(props.error_image || EmptyUserPhoto);
                } else {
                    setImgSrc(props.error_image || EmptySnapshot);
                }
            }
        } else if (status === 3) {
            var cacheImg;
            if (props.u_user_id > -1) {
                cacheImg = ASImageCacheMap['user'][props.u_user_id.toString()];
            } else if (props.log_type > -1 && props.log_id > -1) {
                cacheImg = ASImageCacheMap[props.log_type.toString()] ? ASImageCacheMap[props.log_type.toString()][props.log_id.toString()] : null;
            }
            if (cacheImg) {
                setStatus(0);
                setImgSrc(cacheImg);
            } else {
                if (props.noErrorImage) {
                    props.onError(e);
                } else {
                    setStatus(2);
                    if (props.u_user_id > -1) {
                        setImgSrc(props.error_image || EmptyUserPhoto);
                    } else if (props.log_type > -1 && props.log_id > -1) {
                        setImgSrc(props.error_image || EmptySnapshot);
                    } else {
                        props.onError(e);
                    }
                }
            }
        } else {
            setStatus(2);
            if (props.noErrorImage) {
                props.onError(e);
            } else {
                if (props.u_user_id > -1) {
                    setImgSrc(props.error_image || EmptyUserPhoto);
                } else if (props.log_type > -1 && props.log_id > -1) {
                    setImgSrc(props.error_image || EmptySnapshot);
                } else {
                    props.onError(e);
                }
            }
        }
    };

    return (
        <Card.Img {...ExcludeProps(props, 'force', 'noErrorImage')} alt={props.alt} src={imgSrc} onLoad={handleLoad} onError={handleError} />
    );
}
ASImage.defaultProps = {
    log_type: -1,
    log_id: -1,
    u_user_id: -1,
    src: '',
    alt: '',
    force: false,
    onLoad: function() {},
    onError: function(e) {},
    error_image: null,
    noErrorImage: false
};
export function ClearASImageUserCache(u_user_id) {
    delete ASImageCacheMap['user'][u_user_id];
}

export const RenderPlateNoPhoto = (img, x1, x2, y1, y2, width, plateTextId) => {
    var plateWidth = width;
    x1 = x1 || 0;
    x2 = x2 || 0;
    y1 = y1 || 0;
    y2 = y2 || 0;
    if ((x1 === 0 && x2 === 0) || (y1 === 0 || y2 === 2)) return;

    var parent = img.parentElement;

    var scale = plateWidth / img.naturalWidth * 1000 / (x2 - x1) ;
    var parentHeight = img.naturalHeight * scale * (y2 - y1) / 1000;

    parent.style.width = plateWidth + 'px';
    parent.style.height = parentHeight + 'px';
    parent.style.display = 'block';
    
    img.style.width = (img.naturalWidth * scale) + 'px';
    img.style.height = (img.naturalHeight * scale) + 'px';
    img.style.marginLeft = (x1 / 1000  * img.naturalWidth * scale * -1) + 'px';
    img.style.marginTop = (y1 / 1000  * img.naturalHeight * scale * -1) + 'px';

    if (plateTextId) {
        RenderPlateNoText(plateTextId, plateWidth, parentHeight);
    }
};

export const RenderPlateNoText = (plateTextId, width, height) => {
    var elPlateText = document.getElementById(plateTextId);
    if (elPlateText) {
        elPlateText.style.display = 'block';
        elPlateText.style.width = width + 'px';
        elPlateText.style.height = height + 'px';
        elPlateText.style.fontSize = '70px';

        var fontSize = 70,
            elSpan = elPlateText.children[0];
        while (elSpan.offsetWidth > width && fontSize > 10) {
            fontSize -= 2;
            elPlateText.style.fontSize = fontSize + 'px';
        }
    }
};


export class TimeRangePicker extends React.Component {
    itemHeight = 28;
    scrollTimer = null;
    constructor(props) {
        super(props);

        this.state = {
            startHour: 0,
            startMin: 0,
            endHour: 0,
            endMin: 0
        };

        this.startHourPanel = React.createRef();
        this.startMinPanel = React.createRef();
        this.endHourPanel = React.createRef();
        this.endMinPanel = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleWheel = this.handleWheel.bind(this);
    }

    componentDidMount() {
        this.startHourPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.startMinPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.endHourPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.endMinPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.setVlaue();
    }

    componentWillUnmount() {
        this.startHourPanel.current.removeEventListener('wheel', this.handleWheel);
        this.startMinPanel.current.removeEventListener('wheel', this.handleWheel);
        this.endHourPanel.current.removeEventListener('wheel', this.handleWheel);
        this.endMinPanel.current.removeEventListener('wheel', this.handleWheel);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            this.setVlaue();
        }
        if (this.state.startHour !== prevState.startHour || this.state.startMin !== prevState.startMin ||
            this.state.endHour !== prevState.endHour || this.state.endMin !== prevState.endMin) {
            clearTimeout(this.scrollTimer);
            this.scrollTimer = setTimeout(this.scroll, 50);
        }
    }

    setVlaue = () => {
        var splitTime = this.props.value.split(' - '),
            startTime = splitTime[0],
            endTime = splitTime[1];
        
        var splitStart = startTime.split(':'),
            splitEnd = endTime.split(':');
        this.setState({
            startHour: parseInt(splitStart[0]),
            startMin: parseInt(splitStart[1]),
            endHour: parseInt(splitEnd[0]),
            endMin: parseInt(splitEnd[1])
        });
    };

    scroll = () => {
        if (this.startHourPanel.current) {
            this.startHourPanel.current.scrollTo({top: this.state.startHour * this.itemHeight, behavior: 'smooth'});
        }
        if (this.startMinPanel.current) {
            this.startMinPanel.current.scrollTo({top: this.state.startMin / 5 * this.itemHeight, behavior: 'smooth'});
        }
        if (this.endHourPanel.current) {
            this.endHourPanel.current.scrollTo({top: this.state.endHour * this.itemHeight, behavior: 'smooth'});
        }
        if (this.endMinPanel.current) {
            this.endMinPanel.current.scrollTo({top: this.state.endMin / 5 * this.itemHeight, behavior: 'smooth'});
        }
        this.scrollTimer = null;
    };

    handleChange = (name, value) => {
        var label = '',
            adjustMin;
        const strFormat = '{0}:{1} - {2}:{3}';
        
        switch(name) {
            case 'sh':
                adjustMin = this.state.startMin;
                if (value === this.state.endHour) {
                    if (this.props.ignoreValid && this.state.startMin === this.state.endMin) {
                        adjustMin = this.state.startMin > 0 ? this.state.startMin - 5 : 5;
                    } else if (!this.props.ignoreValid && this.state.startMin >= this.state.endMin) {
                        adjustMin = this.state.endMin - 5;
                    }
                }
                label = format(strFormat, value.toString().padStart(2, '0'), adjustMin.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), this.state.endMin.toString().padStart(2, '0'));
                break;
            case 'sm':
                label = format(strFormat, this.state.startHour.toString().padStart(2, '0'), value.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), this.state.endMin.toString().padStart(2, '0'));
                break;
            case 'eh':
                adjustMin = this.state.endMin;
                if (value === this.state.startHour) {
                    if (this.props.ignoreValid && this.state.startMin === this.state.endMin) {
                        adjustMin = this.state.endMin < 55 ? this.state.endMin + 5 : 0;
                    } else if (!this.props.ignoreValid && this.state.endMin <= this.state.startMin) {
                        adjustMin = this.state.startMin + 5;
                    }
                }
                adjustMin = value === 24 ? 0 : adjustMin;
                label = format(strFormat,  this.state.startHour.toString().padStart(2, '0'), this.state.startMin.toString().padStart(2, '0'), value.toString().padStart(2, '0'), adjustMin.toString().padStart(2, '0'));
                break;
            case 'em':
                label = format(strFormat, this.state.startHour.toString().padStart(2, '0'), this.state.startMin.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), value.toString().padStart(2, '0'));
                break;  
            default:
                return;
        }
        this.props.onChange(label);
    };

    handleClick = e => {
        var name = e.target.getAttribute('name'),
            value = parseInt(e.target.getAttribute('eventkey'));
        
        this.handleChange(name, value);
    };

    handleWheel = e => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = false;  
        var name = e.target.getAttribute('name'),
            step = Math.round(e.deltaY * 0.01),
            value;

        switch(name) {
            case 'sh':
                value = this.state.startHour + step;
                if (value < 0 || value > 23) return;
                if (!this.props.ignoreValid && (value > this.state.endHour || (value === this.state.endHour && this.state.endMin === 0))) {
                    return;
                }
                if (this.startHourPanel.current) {
                    this.startHourPanel.current.scrollTo({top: value * this.itemHeight});
                }
                break;
            case 'sm':
                value = this.state.startMin + step * 5;
                if (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.endMin) {
                    value += step * 5;
                }
                if (value < 0 || value > 55) return;
                if (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value >= this.state.endMin) {
                    return;
                }
                if (this.startMinPanel.current) {
                    this.startMinPanel.current.scrollTo({top: value / 5 * this.itemHeight});
                }
                break;
            case 'eh':
                value = this.state.endHour + step;
                if (value < 0 || value > 24) return;
                if (!this.props.ignoreValid && (value < this.state.startHour || (value === this.state.startHour && this.state.startMin === 55))) {
                    return;
                }
                if (this.endHourPanel.current) {
                    this.endHourPanel.current.scrollTo({top: value * this.itemHeight});
                }
                break;
            case 'em':
                value = this.state.endMin + step * 5;
                if (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.startMin) {
                    value += step * 5;
                }
                if (value < 0 || value > 55) return;
                if ((!this.props.ignoreValid && this.state.startHour === this.state.endHour && value <= this.state.startMin) || (this.state.endHour === 24 && value !== 0)) {
                    return;
                }
                if (this.endMinPanel.current) {
                    this.endMinPanel.current.scrollTo({top: value / 5 * this.itemHeight});
                }
                break;
            default:
                return;
        }
        this.handleChange(name, value);
    };

    render() {
        return (
            <div className='time-range-picker noselect'>
                <div ref={this.startHourPanel} name='sh' className='time-range-picker-column'>
                    {
                        (Array.from(Array(24).keys())).map((item, idx) => {
                            var key = idx.toString().padStart(2, '0');
                            var disabled = !this.props.ignoreValid && (idx > this.state.endHour || (idx === this.state.endHour && this.state.endMin === 0));
                            return <div key={idx} eventkey={key} name='sh' onClick={this.handleClick} className={(idx === this.state.startHour? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>:</span>
                <div ref={this.startMinPanel} name='sm' className='time-range-picker-column'>
                    {
                        (Array.from(Array(12).keys())).map((item, idx) => {
                            var value = idx * 5;
                            var key = value.toString().padStart(2, '0');
                            var disabled = (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.endMin) ||
                                            (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value >= this.state.endMin);
                            return <div key={key} eventkey={key} name='sm' onClick={this.handleClick} className={(value === this.state.startMin? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>-</span>
                <div ref={this.endHourPanel} name='eh' className='time-range-picker-column'>
                    {
                        (Array.from(Array(25).keys())).map((item, idx) => {
                            var key = idx.toString().padStart(2, '0');
                            var disabled = !this.props.ignoreValid && (idx < this.state.startHour || (idx === this.state.startHour && this.state.startMin === 55));
                            return <div key={idx} eventkey={key} name='eh' onClick={this.handleClick} className={(idx === this.state.endHour? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>:</span>
                <div ref={this.endMinPanel} name='em' className='time-range-picker-column'>
                    {
                        (Array.from(Array(12).keys())).map((item, idx) => {
                            var value = idx * 5;
                            var key = value.toString().padStart(2, '0');
                            var disabled = (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.startMin) ||
                                            (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value <= this.state.startMin) || 
                                            (this.state.endHour === 24 && value !== 0);
                            return <div key={key} eventkey={key} name='em' onClick={this.handleClick} className={(value === this.state.endMin? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
            </div>
        );
    }
}
TimeRangePicker.defaultProps = {
    ignoreValid: false,
    value: '00:00 - 00:00',
    onChange: function() {}
};


const FieldToggle = React.forwardRef(({id, disabled, ignoreValid, onClick, onChange, isNextDay, ...props}, ref) => {
    const regex = new RegExp('^([01][0-9]|[2][0-3]):([0-5][05]) - ([01][0-9]|[2][0-4]):([0-5][05])$');
    const intl = useIntl();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleClick = e => {
        e.preventDefault();
        if (!disabled) {
            onClick(e);
        }
    };

    const handleChange = (e) => {
        var val = e.target.value;
        setValue(val);

        if (regex.test(val)) {
            var [strStart, strEnd] = val.split(' - '),
                splitStart = strStart.split(':'),
                splitEnd = strEnd.split(':');;

            var startTime = parseInt(splitStart[0]) * 60 + parseInt(splitStart[1]),
                endTime = parseInt(splitEnd[0]) * 60 + parseInt(splitEnd[1]);
            if (ignoreValid) {
                if (startTime !== endTime) {
                    onChange(val);
                }
            } else if (startTime < endTime && startTime < 1440 && endTime <= 1440) {
                onChange(val);
            }
        }
    };

    const handleBlur = (e) => {
        setValue(props.value);
    };

    return (
        <div ref={ref} className={`time-range-field`} onClick={handleClick} attr-data-memo={isNextDay ? `(${intl.formatMessage({id: 'the_next_day'})})` : ''}>
            <Input  id={id} value={value} disabled={disabled} onChange={handleChange} onBlur={handleBlur} />
        </div>
    );
});

export function TimeRangeField({id, disabled, ignoreValid, onChange, ...props}) {
    const [value, setVlaue] = useState('');
    const [isNextDay, setIsNextDay] = useState(false);
    const pickerRef = useRef(null);

    const validTimeValue = useCallback(() => {
        return (props.startTime > 0 || props.endTime) && props.startTime < 1440 && props.endTime <= 1440;
    }, [props.startTime, props.endTime]);

    useEffect(() => {
        if (props.value) {
            setVlaue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (validTimeValue()) {
            var _val = format('{0}:{1} - {2}:{3}', Math.floor(props.startTime / 60).toString().padStart(2, '0'), Math.floor(props.startTime % 60).toString().padStart(2, '0'),
                        Math.floor(props.endTime / 60).toString().padStart(2, '0'), Math.floor(props.endTime % 60).toString().padStart(2, '0'));
            setVlaue(_val);
            setIsNextDay(props.endTime < props.startTime);
        }       
    }, [props.startTime, props.endTime, validTimeValue]);

    const handleChange = val => {
        if (!val.includes(' - ')) return;

        if (validTimeValue()) {
            var strStart = val.split(' - ')[0],
                strEnd = val.split(' - ')[1],
                splitStart = strStart.split(':'),
                splitEnd = strEnd.split(':');

            var startTime = parseInt(splitStart[0]) * 60 + parseInt(splitStart[1]),
                endTime = parseInt(splitEnd[0]) * 60 + parseInt(splitEnd[1]);
            onChange(val, startTime, endTime);
        } else {
            onChange(val);
        }
    };

    const handleToggle = (nextShow) => {
        if (nextShow) {
            setTimeout(() => {
                if (pickerRef.current) {
                    pickerRef.current.scroll();
                }
            }, 100);
        }
    };

    return (
        <Popover overlayClassName='without-arrow time-range-dropdown' placement="bottomLeft" trigger={['click']} onOpenChange={handleToggle} getPopupContainer={props.getPopupContainer}
            content={
                <TimeRangePicker ref={pickerRef} ignoreValid={ignoreValid} value={value} onChange={handleChange} />
            }
        >
            <FieldToggle id={id} value={value} disabled={disabled} ignoreValid={ignoreValid} isNextDay={isNextDay} onChange={handleChange} />
        </Popover>
    );
}
TimeRangeField.defaultProps = {
    id: '',
    disabled: false,
    getPopupContainer: null,
    value: '00:00 - 24:00',
    startTime: 0,   // min: 0, max: 1435
    endTime: 0,     // min: 5, max: 1440
    ignoreValid: false,
    onChange: function(value, startTime, endTime) {}
};

export function PasswordField({showEye, fakePassword, value, ...props}) {
    return (
        <Input.Password {...props} value={fakePassword ? '********' : value} visibilityToggle={showEye} autocomplete="one-time-code"
            iconRender={(visible) => visible ? <SVGIcon.PasswordHide /> : <SVGIcon.PasswordShow />}
        />
    );
}
PasswordField.defaultProps = {
    value: '',
    showEye: true,
    fakePassword: false
};

export const enumNumberCodeType = {
    PinCode: 0,
    CommonPassword: 1
};
export const NumberCodeField = forwardRef(({type, value, onChange, ...props}, ref) => {
    const fakePwd = useMemo(() => {
        return value && value.includes('*');
    }, [value]);

    const dipValue = useMemo(() => {
        if (fakePwd) {
            return type === enumNumberCodeType.PinCode ? '****' : '********';
        } else {
            return value;
        }
    }, [value, fakePwd, type]);

    const handleChange = e => {
        var value = e.target.value,
            strNum = '', i;
        
        for (i = 0; i < value.length; i++) {
            if (/^\d+$/.test(value[i])) {
                strNum += value[i];
            }
        }
        onChange(strNum);
    };

    return (
        <Input.Password ref={ref} {...props} value={dipValue} onChange={handleChange}
            visibilityToggle={!fakePwd} autocomplete="one-time-code"
            iconRender={(visible) => visible ? <SVGIcon.PasswordHide /> : <SVGIcon.PasswordShow />}
        />
    );
});
NumberCodeField.defaultProps = {
    type: enumNumberCodeType.PinCode,
    value: '',
    onChange: function() {}
};

export function LogViewMode(props) {
    return (
        <Segmented size='sm' className='common-log-view-mode' {...props}
            options={[{
                value: EnumLogViewMode.Photo,
                icon: (
                    <Tooltip placement='bottom' title={<FormattedMessage id="photo_view" defaultMessage="Photo View" />}>
                        <SVGIcon.QueryPhotoMode />
                    </Tooltip>
                )
            }, {
                value: EnumLogViewMode.List,
                icon: (
                    <Tooltip placement='bottom' title={<FormattedMessage id="list_view" defaultMessage="List View" />}>
                        <SVGIcon.QueryListMode />
                    </Tooltip>
                )
            }]}
        />
    )
}
LogViewMode.defaultProps = {
    value: EnumLogViewMode.Photo,
    onChange: function(value) {}
};

export const getTreeSwitcherIcon = (nodeProps) => {
    return <SVGIcon.ArrowDropDown className={nodeProps.expanded ? 'expanded' : ''} />;
};

export const SearchInput = (props) => {
    return (
        <Input {...props} className={`common-search-input ${props.className || ''}`} suffix={<SVGIcon.Search />} />
    );
};

export const TargetDropdown = ({target, onClose, ...props}) => {
    const targetStyle = useMemo(() => {
        if (target) {
            var rect = target.getBoundingClientRect();
            return {
                left: `${(rect.left + rect.right) / 2}px`,
                top: `${rect.top}px`,
                height: `${rect.height}px`
            };
        } else {
            return {
                display: 'none'
            };
        }
    }, [target]);

    const handleOpenChange = (open) => {
        if (!open) {
            onClose();
        }
    };
    
    return (
        <Dropdown {...props} open={!!target} onOpenChange={handleOpenChange}>
            <div style={targetStyle} className='target-dropdown-pos'></div>
        </Dropdown>
    );
};
TargetDropdown.defaultProps = {
    target: null,
    onClose: function() {},

    menu: {},
    placement: 'bottom',
    arrow: {pointAtCenter: true},
    trigger: ['click']
};