import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useContext, Suspense, useMemo } from 'react'
import { format } from 'react-string-format';
import { FormattedMessage } from "react-intl";
import { BrowserRouter, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import packageJson from '../package.json';
import preval from 'preval.macro';

import 'antd/dist/antd.min.css';
import { Tooltip } from 'antd';

import logo from './images/sidebar/gv_cloud_logo.svg';
import logoShort from './images/sidebar/gv_cloud_logo_short.svg';
import './App.css';

import { EnumAccountType, EnumWebSocketCmd, EnumActionType, EnumThemeType, EnumErrorMessage, EnumMessageType, MessageTypeString } from './modules/ASUtils/ASConfig';
import { Constants, AppContext, AppContextProvider } from './Utils';
import { LoadingMask, ShowAlertMessage } from './modules/Common/Common';
import { useVMSGraphql } from './modules/VMS/VMSGraphql';
import SVGIcon from './icons.js';

const Monitor = React.lazy(() => import('./modules/Monitor/Monitor'));
const UserList = React.lazy(() => import('./modules/UserList/UserList'));
const UserSetting = React.lazy(() => import('./modules/UserSetting/UserSetting'));
const Region = React.lazy(() => import('./modules/Region/Region'));
const AccessRule = React.lazy(() => import('./modules/AccessRule/AccessRule'));
const SpecialDay = React.lazy(() => import('./modules/SpecialDay/SpecialDay'));
const Alert = React.lazy(() => import('./modules/Alert/Alert'));
const AccessLog = React.lazy(() => import('./modules/AccessLog/AccessLog'));
// const LPRLog = React.lazy(() => import('./modules/LPRLog/LPRLog'));
const SystemLog = React.lazy(() => import('./modules/SystemLog/SystemLog'));
const Operators = React.lazy(() => import('./modules/Operators/Operators'));
const LockdownModal = React.lazy(
    () => import("./modules/Monitor/Monitor").then(module => ({default: module.LockdownModal}))
);
const Notification = React.lazy(() => import('./modules/Notification/Notification'));

const dateTimeStamp = preval`module.exports = new Date().toLocaleDateString() + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0') + ':' + new Date().getSeconds().toString().padStart(2, '0');`

function Application() {
	const vmsGraphql = useVMSGraphql();
	const [isLogin, setIsLogin] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [showSites, setShowSites] = useState(false);
	const [showAccount, setShowAccount] = useState(false);
	const [showLockdown, setShowLockdown] = useState(false);
	const [alertMsg, setAlertMsg] = useState({type: 'error', msg: '', onClose: null});

	const context = useContext(AppContext);

	const profileAvatar = useMemo(() => {
		if (context.accountInfo.profile_picture) {
			return <img alt='' src={context.accountInfo.profile_picture} onLoad={e => e.target.classList.add('loaded') } />
		}
		return null;
	}, [context.accountInfo.profile_picture]);

	useEffect(() => {
		// create meta tags
		createMeta('version', packageJson.version);
		createMeta('build-time', dateTimeStamp);

		context.verifyUser(() => {
			context.ajaxLogin((success, accountInfo) => {
				setIsLogin(success);
				if (success) {
					context.addWSNotification(receiveNotification);
					setTimeout(() => {	// must get device list for lockdown status
						context.getDeviceList();
					}, 1000);
				} else {
					setAlertMsg({...alertMsg, msg: EnumErrorMessage[accountInfo] ? <FormattedMessage id={EnumErrorMessage[accountInfo]} /> : accountInfo });
				}
			});
		});
		
				
		setIsOpen(window.localStorage.getItem(Constants.storageNames.openSiderbar) === '1');

		const handleClickOutside = e => {
			if (!e.target.closest('.gv-service-panel') && !e.target.closest('.app-sidebar-brand')) {
				setShowSites(false);
			}
			if (!e.target.closest('.gv-account-panel') && !e.target.closest('.app-sidebar-photo')) {
				setShowAccount(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			context.removeWSNotification(receiveNotification);
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.body.classList.remove('dark');
		if (context.accountInfo.theme === EnumThemeType.Dark ||
			(context.accountInfo.theme === EnumThemeType.OSDefault && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				
			document.body.classList.add('dark');
		}
	}, [context.accountInfo.theme]);

	const createMeta = (name, content) => {
		var meta = document.createElement('meta');
		meta.name = name;
		meta.content = content;
		document.head.append(meta);
	};

	const receiveNotification = data => {
		switch (data.cmd_id) {
			case EnumWebSocketCmd.ACCOUNT_DATA_CHANGED:
				var action_type = parseInt(data.data1);
				if (action_type === EnumActionType.Delete) {
					setAlertMsg({...alertMsg, msg: <FormattedMessage id="account_been_deleted" defaultMessage="Your login account has been deleted." />});
				} else if (action_type === EnumActionType.Edit) {
					setAlertMsg({
						...alertMsg,
						type: 'warning',
						msg: <FormattedMessage id='privilege_changed_reload_page' />,
						onClose: () => {
							setAlertMsg({...alertMsg, msg: ''});
							window.location.reload();
						}
					});
					setTimeout(() => {
						window.location.reload();
					}, 10 * 1000);
				}
				break;

			case EnumWebSocketCmd.ACCOUNT_SUSPEND:
				setAlertMsg({...alertMsg, msg: <FormattedMessage id="account_been_suspended" defaultMessage="Your account has been suspended." />});
				break;

			case EnumWebSocketCmd.INVALID_TOKEN:
				setAlertMsg({
					...alertMsg,
					type: 'warning',
					msg: <FormattedMessage id="err_msg_token_invalid" defaultMessage="Your session has timed out. Please log in again." />,
					onClose: () => {
						handleLogoutClick();
						window.location = `${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`;
					}
				});
				break;

			case EnumWebSocketCmd.VMS_WEBSOCKET:
				if (data.action === 'LOGOUT') {
					const doLogout = () => {
						handleLogoutClick();
						window.location = `${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`;
					};

					setAlertMsg({
						...alertMsg,
						type: 'warning',
						msg: <FormattedMessage id={`VMS_${data.type}`} />,
						onClose: () => {
							setAlertMsg({...alertMsg, msg: ''});
							doLogout();
						}
					});
					setTimeout(doLogout, 10 * 1000);
				}
				break;

			default:
				break;
		}
	};

	const handleSidebarDock = () => {
		if (isOpen) {
			window.localStorage.removeItem(Constants.storageNames.openSiderbar);
		} else {
			window.localStorage.setItem(Constants.storageNames.openSiderbar, '1');
		}
		setIsOpen(!isOpen);
	};

	const handleLogoutClick = e => {
		context.sendWS({cmd_id: EnumWebSocketCmd.ACCOUNT_LOGOUT});
		window.localStorage.removeItem(Constants.storageNames.accountInfo);
	};

	const handelCloseAlertMsg = () => {
		setAlertMsg({...alertMsg, msg: ''});
		window.location = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;
	};

	const handleMapClick = (e) => {
		e.preventDefault();
		vmsGraphql.mutationTempToken((success, token) => {
			if (success) {
				window.open(`${Constants.gvMapUrl}?token=${token}`);
			}
		});
	};

	const renderSideMenuItem = (linkTo, text, icon) => {
		return (
			<Tooltip placement='right' title={text} trigger={isOpen ? [] : ['hover']}>
				<li>
					<NavLink to={linkTo}>
						{icon}
						<span>{text}</span>
					</NavLink>
				</li>
			</Tooltip>
		);
	};

	return (
		<BrowserRouter>
		{
			process.env.REACT_APP_TYPE === 'debug' &&
			<div className='app-debug'>{format('Debug-{0}-{1}', packageJson.version, dateTimeStamp)}</div>
		}

		{
			isLogin ?
			<div className='App'>
				<div className={'app-main' + (isOpen? ' open' : '')}>
					<aside className={`app-sidebar noselect ${context.lockdownRegions.length > 0 ? 'lockdown' : ''}`}>
													
						<div className='app-sidebar-brand' onClick={() => setShowSites(!showSites)}>
							<Tooltip placement={isOpen ? 'bottom' : 'bottomLeft'} title='GV-Cloud Apps' arrowPointAtCenter={true} trigger={['hover']}>
								<div>
									<img src={isOpen ? logo : logoShort} className="d-inline-block align-top" alt="GV-Cloud" />
								</div>
							</Tooltip>
						</div>

						<div className={'gv-service-panel' + (showSites ? ' show' : '')} onClick={(e) => setShowSites(false)}>
							<a href={`${Constants.gvCloudUrl}cloud_vms`} target="_blank" rel="noopener noreferrer">
								<SVGIcon.LogoVideoMonitor />
								<span><FormattedMessage id="cloud_vms" defaultMessage="Cloud VMS" /></span>
							</a>
							<NavLink to='/Monitor' className='active'>
								<SVGIcon.LogoAccessControl />
								<span><FormattedMessage id="access_control" defaultMessage="Access Control" /></span>
							</NavLink>
							{/* <a href={`/`} target="_blank" rel="noopener noreferrer" disabled={true} onClick={e => e.preventDefault()}>
								<SVGIcon.LogoIOT />
								<span><FormattedMessage id="iot" defaultMessage="IOT" /></span>
							</a> */}
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={context.accountInfo.master ? [] :['hover']}>
								<a href={!!context.accountInfo.master && `${Constants.gvCloudUrl}vpn`} target="_blank" rel="noopener noreferrer" disabled={!context.accountInfo.master} className={context.accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoVPN />
									<span><FormattedMessage id="vpn" defaultMessage="VPN" /></span>
								</a>
							</Tooltip>
							<a href={Constants.gvMapUrl} target="_blank" rel="noopener noreferrer" onClick={handleMapClick}>
								<SVGIcon.LogoMap />
								<span><FormattedMessage id="map" defaultMessage="Map" /></span>
							</a>
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={context.accountInfo.master ? [] :['hover']}>
								<a href={!!context.accountInfo.master && `${Constants.gvCloudUrl}audit_log`} target="_blank" rel="noopener noreferrer" disabled={!context.accountInfo.master} className={context.accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoAuditLog />
									<span><FormattedMessage id="audit_log" defaultMessage="Audit Log" /></span>
								</a>
							</Tooltip>
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={context.accountInfo.master ? [] :['hover']}>
								<a href={!!context.accountInfo.master && `${Constants.gvCloudUrl}account_management`} target="_blank" rel="noopener noreferrer" disabled={!context.accountInfo.master} className={context.accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoAccountSetting />
									<span><FormattedMessage id="account_management" defaultMessage="Account Management" /></span>
								</a>
							</Tooltip>
						</div>

						<ul>
							{renderSideMenuItem('/Monitor', <FormattedMessage id="monitoring" defaultMessage="Monitoring" />, <SVGIcon.Monitoring />)}
							{renderSideMenuItem('/UserList', <FormattedMessage id="user_list" defaultMessage="User List" />, <SVGIcon.UserList />)}
							{renderSideMenuItem('/AccessLog', <FormattedMessage id="access_event_log" defaultMessage="Access / Event Log" />, <SVGIcon.AccessLog />)}
							{/* {renderSideMenuItem('/LPRLog', <FormattedMessage id={MessageTypeString(EnumMessageType.LPR)} />, <SVGIcon.LPRLog />)} */}
							{renderSideMenuItem('/SystemLog', <FormattedMessage id={MessageTypeString(EnumMessageType.System)} />, <SVGIcon.SystemLog />)}
							{renderSideMenuItem('/Alert', <FormattedMessage id="alert" defaultMessage="Alert" />, <SVGIcon.Alert />)}
							{renderSideMenuItem('/AccessRule', <FormattedMessage id="access_rule" defaultMessage="Access Rule" />, <SVGIcon.AccessRule />)}
							{
								context.accountInfo.type !== EnumAccountType.user &&
								<>
									{renderSideMenuItem('/SpecialDay', <FormattedMessage id="special_day" defaultMessage="Special Day" />, <SVGIcon.SpecialDay />)}
									{renderSideMenuItem('/Region', <FormattedMessage id="region_device" defaultMessage="Region / Device" />, <SVGIcon.Region />)}
								</>
							}
							{renderSideMenuItem('/Operators', <FormattedMessage id="operator_privileges" defaultMessage="Operator Privileges" />, <SVGIcon.Operator />)}
						</ul>
						
						{
							context.accountInfo.type !== EnumAccountType.user &&
							<Tooltip placement='right' title={<FormattedMessage id="lockdown" defaultMessage="Lockdown" />} trigger={isOpen ? [] : ['hover']}>
								<button className='app-sidebar-lockdown' onClick={() => setShowLockdown(true)}>
									<div>
										<SVGIcon.Lockdown />
									</div>
									<span><FormattedMessage id="lockdown" defaultMessage="Lockdown" /></span>
								</button>
							</Tooltip>
						}
						
						<div className='app-sidebar-photo' onClick={e => setShowAccount(!showAccount)}>
							<div className='gv-user-avatar'>{profileAvatar}</div>
							<span>{context.accountInfo.name}</span>
							{
								context.accountInfo.suspend_time &&
								<Tooltip placement='right' title={<FormattedMessage id='account_suspended_format' values={{0: moment.utc(context.accountInfo.suspend_time).local().format('YYYY/MM/DD')}} />}>
									<SVGIcon.FirmwareDirty className='suspend' />
								</Tooltip>
							}
						</div>
						<div className={'gv-account-panel' + (showAccount ? ' show' : '')}>
							<div className='gv-user-avatar'>{profileAvatar}</div>
							<span className='name'>{context.accountInfo.name}</span>
							<span className='email'>{context.accountInfo.email}</span>
							<a className='setting' href={`${Constants.gvCloudUrl}settings`} target="_blank" rel="noopener noreferrer" onClick={() => setShowAccount(false)}>
								<FormattedMessage id="account_setting" defaultMessage="Account Setting" />&nbsp;<SVGIcon.Edit />
							</a>
							<a className='logout' href={`${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`} onClick={handleLogoutClick}><SVGIcon.Logout />&nbsp;<FormattedMessage id="logout" defaultMessage="LOGOUT" /></a>
						</div>
						<div className={'siderbar-dock-button' + (isOpen? ' open' : '')} onClick={handleSidebarDock}>
							{isOpen? <SVGIcon.DockClose /> : <SVGIcon.DockOpen /> }
						</div>
					</aside>
					<div className="app-content">
						<Routes>
							<Route path="/Monitor" element={<Suspense fallback={<LoadingMask show={true} />}><Monitor/></Suspense>}/>
							<Route path="/UserList" element={<Suspense fallback={<LoadingMask show={true} />}><UserList/></Suspense>}/>
							<Route path="/UserList/Setting" element={<Suspense fallback={<LoadingMask show={true} />}><UserSetting/></Suspense>}/>
							<Route path="/UserList/Setting/:id" element={<Suspense fallback={<LoadingMask show={true} />}><UserSetting /></Suspense>} />
							<Route path="/AccessLog" element={<Suspense fallback={<LoadingMask show={true} />}><AccessLog/></Suspense>}/>
							<Route path="/SystemLog" element={<Suspense fallback={<LoadingMask show={true} />}><SystemLog/></Suspense>}/>
							{/* <Route path="/LPRLog" element={<Suspense fallback={<LoadingMask show={true} />}><LPRLog/></Suspense>}/> */}
							<Route path="/Operators" element={<Suspense fallback={<LoadingMask show={true} />}><Operators/></Suspense>}/>
							<Route path="/AccessRule" element={<Suspense fallback={<LoadingMask show={true} />}><AccessRule/></Suspense>}/>
							<Route path="/Alert" element={<Suspense fallback={<LoadingMask show={true} />}><Alert/></Suspense>}/>
							{
								context.accountInfo.type !== EnumAccountType.user &&
								<>
									<Route path="/Region" element={<Suspense fallback={<LoadingMask show={true} />}><Region/></Suspense>}/>
									<Route path="/SpecialDay" element={<Suspense fallback={<LoadingMask show={true} />}><SpecialDay/></Suspense>}/>
								</>
							}
							<Route path='*' element={<Navigate to="/Monitor" replace />} />
						</Routes>
					</div>
				</div>

				{
					!!context.accountInfo.id &&
					<Suspense fallback={<LoadingMask show={true} />}><Notification uid={CryptoJS.MD5(context.accountInfo.id.toString(16)).toString()} /></Suspense>
				}

				<Suspense fallback={<LoadingMask show={true} />}>
					<LockdownModal show={showLockdown} onClose={() => setShowLockdown(false)} />
				</Suspense>
			</div>
			:
			<LoadingMask show={true} />
		}

			<ShowAlertMessage {...alertMsg} show={!!alertMsg.msg} onClose={alertMsg.onClose || handelCloseAlertMsg} />
		</BrowserRouter>
	);
}

export default function App() {
	return (
		<AppContextProvider>
			<Application/>
		</AppContextProvider>
	);
}
